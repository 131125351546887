import React, { memo, useCallback } from "react";

import ClockIcon from "astrid-components/lib/components/Assets/Icons/Clock";
import Timeline from "astrid-components/lib/components/Audio/Timeline";
import Flex from "astrid-components/lib/components/Layout/Flex";
import MouseLabel from "astrid-components/lib/components/Modules/MouseLabel";
import { push } from "astrid-hooks/src/useHistory";
import useTemp from "astrid-hooks/src/useTemp";

import disableAutoScroll from "../../helpers/disableAutoScroll";
import enableAutoScroll from "../../helpers/enableAutoScroll";
import * as firebase from "../../helpers/firebase";
import jumpToMarker from "../../helpers/jumpToMarker";
import selectMarker from "../../helpers/selectMarker";
import useSelectionRange from "../../hooks/useSelectionRange";
import useText from "../../hooks/useText";
import { translations } from "../../i18n";
import { useRecorder } from "../../state/permissions";
import { useSelectedMarker } from "../../state/selectedMarker";
import { timeline } from "../../state/timeline";

function Range({ marker, position }) {
	const [range, detections] = useSelectionRange(position, marker.expand);
	const positions = detections && detections.map(([p]) => p);
	const [tempRange, setTempRange] = useTemp(range);

	const length = tempRange[1] - tempRange[0];

	const onMove = useCallback(
		(side, delta) => {
			const copy = [...range];
			const x = range[side] + delta;
			const closest = positions.reduce((a, b) => (Math.abs(b - x) < Math.abs(a - x) ? b : a));

			if (closest) {
				const max = positions.find((p) => position <= p);
				const min = positions[positions.indexOf(max) - 1];

				copy[side] = side === 0 ? Math.min(min, closest) : Math.max(max, closest);
				setTempRange(copy);
			}
		},
		[position, positions, range, setTempRange],
	);

	const onRelease = useCallback(
		() =>
			push(
				"Expandera markering",
				firebase.commit(
					marker.update({
						expand: marker.expand.map(
							(v, i) => v + (positions.indexOf(tempRange[i]) - positions.indexOf(range[i])),
						),
					}),
				),
			),
		[marker, positions, range, tempRange],
	);

	return (
		tempRange && (
			<Timeline.Range
				expand
				length={length}
				color={marker.color}
				position={tempRange[0]}
				onMove={onMove}
				onRelease={onRelease}
			/>
		)
	);
}

function Marker({ marker }) {
	const t = useText();
	const recorder = useRecorder();
	const selectedMarker = useSelectedMarker();

	const selected = selectedMarker && selectedMarker[0] === marker.id;

	const [position, setPosition] = useTemp(marker.position);

	const onClick = useCallback(() => {
		selectMarker(marker.id, true);
		jumpToMarker(marker.id);
	}, [marker.id]);

	const onMove = useCallback(
		(position) => {
			setPosition(position);
			disableAutoScroll();
		},
		[setPosition],
	);

	const onChange = useCallback(
		(nextPosition) => {
			push(
				t("moveMarker", "Move marker"),
				firebase.commit(timeline.set("markers", marker.id, (position || nextPosition) / 1000)),
			);
			enableAutoScroll(true);
		},
		[marker.id, position, t],
	);

	return (
		<>
			{recorder && selected && marker.showRecord && <Range marker={marker} position={position} />}

			<Timeline.Marker
				active={selected}
				position={position}
				color={marker.color}
				onMove={onMove}
				onClick={onClick}
				onChange={onChange}
			>
				<MouseLabel.Trigger
					label={
						marker.stale &&
						t("staleMarker", "This marker is markerd as stale and might not be in the correct position.")
					}
				>
					<Flex>
						{marker.short || translations.markerTypes[marker.type]}
						{marker.stale && <ClockIcon size={14} style={{ marginLeft: 5 }} />}
					</Flex>
				</MouseLabel.Trigger>
			</Timeline.Marker>
		</>
	);
}

export default memo(Marker);
