import React, { useCallback, useEffect, useMemo } from "react";

import Timeline from "astrid-components/lib/components/Audio/Timeline";
import MouseLabel from "astrid-components/lib/components/Modules/MouseLabel";
import { push } from "astrid-hooks/src/useHistory";
import useMouseTouch from "astrid-hooks/src/useMouseTouch";

import cutClips from "../../helpers/cutClips";
import * as firebase from "../../helpers/firebase";
import * as master from "../../helpers/master";
import useClipAtPosition from "../../hooks/useClipAtPosition";
import useClosestSilence from "../../hooks/useClosestSilence";
import useText from "../../hooks/useText";
import { isAction } from "../../state/action";
import { setTool } from "../../state/tool";
import { transactions } from "../../state/transactions";

export default function TrashTool({ x, y } = {}) {
	const t = useText();
	const size = Timeline.useSize();
	const offset = Timeline.useOffset();

	const level = useMemo(() => (y ? Math.round(y) + 1 : 0), [y]);

	const silence = useClosestSilence(level === 2 && x);
	const clip = useClipAtPosition(level === 1 && x);

	const position = useMemo(() => (clip && clip.position) || (silence && silence[0]), [silence, clip]);

	const disabled = y < 0 || y > 1;

	const onMouseUp = useCallback(() => {
		setTool();
		MouseLabel.setLabel();

		if (!disabled) {
			if (clip) {
				const canDelete =
					clip &&
					clip.id &&
					(!isAction("record") || !clip.active) &&
					clip.position + (clip.end || 0) >= offset &&
					clip.position <= offset + size;

				if (canDelete) {
					push(
						t("deleteClip", "Delete clip"),
						firebase.commit([
							clip.remove(),
							transactions.create(clip.id, "remove"),
							master.modify(clip.position),
						]),
					);
				}
			} else if (silence) {
				push(
					t("deleteSilence", "Delete silence"),
					firebase.commit(
						cutClips({
							range: silence,
							fadein: 50,
							fadeout: 100,
							overflow: true,
							transaction: true,
						}),
					),
				);
			}
		}
	}, [disabled, clip, silence, offset, size, t]);

	useEffect(() => {
		MouseLabel.setLabel("Radera");
	}, []);

	useMouseTouch("up", onMouseUp, window, true);

	if (disabled) return false;

	const length = (clip && clip.end - clip.start) || (silence && silence[1] - silence[0]);

	return <Timeline.Range color="negative" position={position} length={length} />;
}
