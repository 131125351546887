import React, { memo, useCallback } from "react";

import Timeline from "astrid-components/lib/components/Audio/Timeline";
import clamp from "astrid-helpers/src/clamp";
import lerp from "astrid-helpers/src/lerp";
import useMouseTouch from "astrid-hooks/src/useMouseTouch";

import * as firebase from "../../helpers/firebase";
import { clips } from "../../state/clips";
import { recordings } from "../../state/recordings";
import { setTool } from "../../state/tool";
import { getSelectedWord } from "../../state/word";
import { words } from "../../state/words";

const min = 0;

function Word({ x, y }) {
	const max = Timeline.useSize() / 10;
	const length = clamp(lerp(min, max, y), min, max);
	const disabled = y < 0 || y > 1;

	useMouseTouch(
		"up",
		useCallback(
			(e) => {
				e.stopPropagation();
				setTool();

				if (!disabled) {
					const word = words.get(getSelectedWord()[0]);
					const clip = clips.atPosition(x + length / 2);

					if (word && clip) {
						const data = {
							length,
							clip: clip.id,
							start: Math.max(0, clip.toInside(x)),
						};

						const { recording } = clip;

						if (recording.url) {
							data.file = recording.url;
							data.filePath = recording.source;
						}

						const id = word.save(data);
						firebase.commit(word);

						if (!recording.url) {
							const listener = (recording) => {
								if (recording.url) {
									word.save(
										{
											...data,
											file: recording.url,
											filePath: recording.source,
										},
										id,
									);
									firebase.commit(word);
									recordings.events.off(recording.id, listener);
								}
							};

							recordings.events.on(recording.id, listener);
						}
					}
				}
			},
			[disabled, length, x],
		),
		window,
		true,
	);

	if (disabled) return false;

	return <Timeline.Range position={x} length={length} />;
}

export default memo(Word);
