import { useEffect, useState } from "react";

import { version } from "../../package.json";

console.log("Installed version:", version);

export default function useVersion() {
	const [update, setUpdate] = useState(false);

	useEffect(() => {
		fetch("/version.json?" + +new Date())
			.then((response) => response.json())
			.then((json) => {
				console.log("Latest version:", json.version);
				if (json.version !== version) {
					setUpdate(true);
				}
			});
	}, []);

	return update;
}
