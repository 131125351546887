import uniqid from "uniqid";
import dot from "dot-object";

dot.override = true;

export default class Document {
	constructor(id, data = {}, collection) {
		this.deps = [];
		this.data = data;
		this.updates = {};
		this.exists = !!id;
		this.id = id || uniqid();
		this.collection = collection;
	}

	get key() {
		return this.id;
	}

	get path() {
		return `${this.collection.path}/${this.id}`;
	}

	get deleted() {
		return this.data.deleted;
	}

	set snapshot(snapshot) {
		this.data = snapshot;
	}

	update(data) {
		this.updates = { ...this.updates, ...data };

		return this;
	}

	remove() {
		return this.update({ deleted: true });
	}

	commit() {
		const { id, data, updates } = this;

		const next = dot.object({ ...data, ...updates });

		this.deps = [];
		this.updates = {};

		if (this.collection) {
			this.collection.set(id, next);
		} else {
			this.snapshot = next;
		}

		return this.exists ? updates : data;
	}

	rollback() {
		const map = {};

		for (const key of Object.keys(this.updates)) {
			map[key] = dot.pick(key, this.data);
			map[key] = typeof map[key] === "undefined" ? null : map[key];
		}

		return () => {
			this.updates = map;
			if (!this.exists) this.updates.deleted = true;
			return this.commit();
		};
	}

	clear() {
		this.updates = {};
	}
}
