import { useEffect } from "react";

import { useLoadingValue } from "astrid-firestore/src/hooks";

export default function useAsyncValue(callback, initalLoading) {
	const { value, loading, error, setValue, setError } = useLoadingValue(initalLoading);

	useEffect(() => {
		if (callback) {
			callback().then(setValue).catch(setError);
		}
	}, [callback]);

	return [value, loading, error];
}
