import React, { memo, useMemo } from "react";

import Select from "astrid-components/lib/components/Inputs/Select";

import { setStudioId, useStudioId } from "../../state/studio";
import { studios } from "../../state/studios";

function SelectStudio(props) {
	const all = studios.useAll();
	const studioId = useStudioId();

	const options = useMemo(
		() =>
			all.map(({ id, label }) => {
				return {
					value: id,
					label,
				};
			}),
		[all],
	);

	return (
		<Select search value={studioId} options={options} placeholder="Välj studio" onChange={setStudioId} {...props} />
	);
}

export default memo(SelectStudio);
