import React, { memo } from "react";

import useScopeParts from "../../hooks/useScopeParts";

import Part from "./Part";

function Parts() {
	const parts = useScopeParts();

	return parts.map((part) => <Part key={part.id} part={part} />);
}

export default memo(Parts);
