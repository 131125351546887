import createStore from "astrid-helpers/src/createStore";
import flattenIntervals from "astrid-helpers/src/flattenIntervals";
import msToTime from "astrid-helpers/src/msToTime";
import { toHoursAndMinutes } from "astrid-helpers/src/time";
import { getProductionId, setProductionId } from "astrid-studio-web/src/state/productionId";

import * as firebase from "../helpers/firebase";
import params from "../helpers/params";

import { onAction } from "./action";
import { clips } from "./clips";

export const [getSession, setSession, useSession] = createStore();

const id = params.get("session");

if (id) {
	firebase.events.on("session", (session) => {
		console.log('firebase.events.on("session")', session);

		if (session?.data.production) {
			setSession(session);

			if (getProductionId() !== session.data.production) {
				setProductionId(session.data.production);
			}
		}
	});

	firebase.worker.session(id);

	// Start session when record button is clicked
	onAction(({ type }) => {
		if (type === "record") {
			const session = getSession();

			if (session && !session.data.arrival) {
				firebase.write([
					[
						"update",
						session.path,
						{
							arrival: toHoursAndMinutes(),
						},
					],
				]);
			}
		}
	});

	// Save session data everytime a new clip has been added
	let prevClipId;

	clips.events.on("update", () => {
		const session = getSession();

		if (session && clips.created.length > 0) {
			const nextClipId = clips.created[clips.created.length - 1];
			const nextClip = clips.get(nextClipId);

			if (prevClipId !== nextClipId && nextClip && nextClip.end && nextClip.pageOut) {
				prevClipId = nextClipId;

				const created = clips
					.filter((clip) => clips.created.includes(clip.id))
					.sort((a, b) => a.position - b.position);

				const pages = flattenIntervals(
					created.filter((clip) => clip.pageIn && clip.pageOut).map((clip) => [clip.pageIn, clip.pageOut]),
				);

				const data = {
					finish: toHoursAndMinutes(),
					cubaseOut: msToTime(nextClip.toOutside(nextClip.end), false),
				};

				if (pages.length > 0) {
					data.pageInterval = pages.map(([a, b]) => ({ in: a, out: b }));
				}

				if (!session.data.cubaseIn) {
					data.cubaseIn = msToTime(created[0].position, false);
				}

				firebase.write([["update", session.path, data]]);
			}
		}
	});
}
