import { getProductionId } from "../state/productionId";
import { timeline } from "../state/timeline";

import Clip from "./clip";
import Collection from "./collection";

export default class Clips extends Collection {
	constructor() {
		super();

		this.created = [];
	}

	clear() {
		super.clear();

		this.created = [];
	}

	get path() {
		return `productions/${getProductionId()}/clips`;
	}

	set(id, data) {
		return super.set(id, new Clip(id, data, this));
	}

	create(number, { track, position, ...data }) {
		const clip = new Clip(null, { ...data, number, track: track || 1, z: parseInt(number) }, this);

		if (position !== undefined) {
			clip.deps = [timeline.set("clips", clip.id, position)];
		}

		this.created.push(clip.id);

		return super.create(clip);
	}

	filterer(a) {
		return a.recording;
	}

	sorter(a, b) {
		return a.z - b.z;
	}

	atPosition(position, ignoreId, track = 1) {
		return this.slice()
			.reverse()
			.find((clip) => clip.track === track && clip.id !== ignoreId && clip.isInside(position));
	}

	toLeft(position) {
		return this.map((clip) => {
			clip.dist = position - (clip.position + clip.end - clip.start);
			return clip;
		})
			.filter(({ dist, track }) => track === 1 && dist > 0)
			.sort((a, b) => a.dist - b.dist)[0];
	}

	toRight(position, ignoreIds = []) {
		return this.filter((clip) => clip.track === 1 && !ignoreIds.includes(clip.id) && clip.position > position).sort(
			(a, b) => a.position - b.position,
		)[0];
	}

	between(start, end) {
		return this.filter((clip) => clip.toOutside(clip.end) >= start && clip.position <= end);
	}

	byNumber(number) {
		return this.filter((clip) => clip.number === number);
	}

	scope({ start, length }) {
		return this.between(start, start + length);
	}

	toObjectArray() {
		return this.map((clip) => clip.toObject());
	}
}
