import Timeline from "astrid-components/lib/components/Audio/Timeline";

import Parts from "../classes/parts";
import { events, worker } from "../helpers/firebase";

import { onProductionId } from "./productionId";

export const parts = new Parts();

events.on("parts", (snapshot) => {
	parts.snapshot = snapshot;

	if (Timeline.getDuration() === Timeline.minDuration) {
		Timeline.setDuration(parts.duration);
	}
});

onProductionId((id) => {
	if (id) {
		worker.parts(id);

		return () => parts.clear();
	}
});
