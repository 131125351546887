import * as Comlink from "comlink";

import { isLive } from "astrid-helpers/src/env";
import eventTarget from "astrid-helpers/src/eventTarget";

import Worker from "../workers/firebase.worker?worker";

export const events = eventTarget();

export const worker = Comlink.wrap(new Worker());

export function write(writes, undoes, collections) {
	if (writes.length > 0) {
		worker.batch(writes);

		collections && collections.forEach((collection) => collection.update());
	}

	return () => {
		if (undoes.length > 0) {
			worker.batch(
				undoes.map(([method, path, rollback]) => {
					const write = [method, path];
					const data = rollback && rollback();
					if (method !== "delete") write.push(data);
					return write;
				}),
			);

			collections && collections.forEach((collection) => collection.update());
		}

		return () => write(writes, undoes, collections);
	};
}

export function commit(documents) {
	documents = Array.isArray(documents) ? documents : [documents];

	const paths = {};
	const writes = [];
	const undoes = [];
	const collections = [];

	const push = (doc) => {
		if (paths[doc.path]) return;
		paths[doc.path] = true;

		doc.deps.forEach(push);

		if (doc.exists) {
			doc.data && undoes.push(["update", doc.path, doc.rollback()]);
			writes.push(["update", doc.path, doc.commit()]);
		} else {
			undoes.push(["delete", doc.path, doc.rollback()]);
			writes.push(["set", doc.path, doc.commit()]);
		}

		if (doc.collection && !collections.includes(doc.collection)) {
			collections.push(doc.collection);
		}
	};

	documents.filter((d) => d).forEach(push);

	return write(writes, undoes, collections);
}

worker.env(isLive);
worker.onMessage(Comlink.proxy(({ key, value }) => key && events.emit(key, value)));
