import Timeline from "astrid-components/lib/components/Audio/Timeline";
import createStore from "astrid-helpers/src/createStore";

import params from "../helpers/params";
import { stopRecorder } from "../services/recorder";

import { isAction } from "./action";
import { onProduction, onProductionChanged } from "./production";
import { getProfile, getUid } from "./user";

export const [getRole, setRole, useRole, onRole] = createStore();
export const [getAdmin, setAdmin, useAdmin] = createStore(false);
export const [getManager, setManager, useManager] = createStore(false);
export const [getReader, setReader, useReader] = createStore(false);
export const [getEditor, setEditor, useEditor] = createStore(false);
export const [getProofer, setProofer, useProofer] = createStore(false);
export const [getRecorder, setRecorder, useRecorder] = createStore(false);
export const [getProductionProofer, setProductionProofer, useProductionProofer] = createStore(false);
export const [getProductionEditor, setProductionEditor, useProductionEditor] = createStore(false);

export function isRole(role) {
	return getRole() === role;
}

onProductionChanged((production) => {
	const profile = getProfile();

	let role = "reader";

	if (profile) {
		const uid = getUid();
		const { permissions } = profile;

		const producerPermissions = production?.producer && permissions?.producer?.[production.producer];

		const productionProofer = permissions && production?.proofer?.includes(uid);

		const productionEditor = permissions && production?.editor?.includes(uid);

		if (production) {
			if (producerPermissions?.includes("producerAdmin")) {
				role = "admin";
			} else if (production?.manager?.includes(uid)) {
				role = "manager";
			} else if (production?.editor?.includes(uid)) {
				role = "editor";
			} else if (producerPermissions?.includes("producerRecorder")) {
				role = "recorder";
			} else if (production?.proofer?.includes(uid)) {
				role = "proofer";
			}
		}

		if (["proofer", "reader", "recorder", "manager"].includes(params.get("role"))) {
			role = params.get("role");
		}

		setProofer(["proofer", "recorder", "editor", "manager", "admin"].includes(role));
		setRecorder(["recorder", "editor", "manager", "admin"].includes(role));
		setEditor(["editor", "manager", "admin"].includes(role));
		setManager(["manager", "admin"].includes(role));
		setAdmin(role === "admin");
		setProductionProofer(productionProofer);
		setProductionEditor(productionEditor);
	}

	setRole(role);
	setReader(true);
});

onProduction((production) => {
	if (production) {
		const { modified } = production.render;

		// Lock the timeline from the time of the earliest modification
		Timeline.setLocked(modified);

		// If the user is a proofer, stop the player if the production is locked
		if (getRole() === "proofer") {
			if (modified < Timeline.getPosition() && isAction("start")) {
				stopRecorder();
			}
		}
	}
});
