import React, { memo, useCallback, useState } from "react";

import RotateIcon from "astrid-components/lib/components/Assets/Icons/Rotate";
import Button from "astrid-components/lib/components/Inputs/Button";
import Label from "astrid-components/lib/components/Inputs/Label";
import Flex from "astrid-components/lib/components/Layout/Flex";
import MouseLabel from "astrid-components/lib/components/Modules/MouseLabel";
import Text from "astrid-components/lib/components/Text/Text";
import zip from "astrid-helpers/src/zip";

import midi from "../../helpers/midi";
import txt from "../../helpers/txt";
import useText from "../../hooks/useText";
import { parts } from "../../state/parts";
import { useProduction } from "../../state/production";

function Download() {
	const t = useText();
	const production = useProduction();

	const [count, setCount] = useState("");
	const [loading, setLoading] = useState();

	const onClick = useCallback(
		async (type) => {
			setLoading(true);
			await zip(
				`${production.title}_${type}`,
				parts.map((part) => [part.name, part[type].url]),
				setCount,
			);
			setLoading(false);
		},
		[production?.title],
	);

	const disabled = production.modified;

	const disabledStyles = {
		opacity: 0.4,
		cursor: "default",
		backgroundColor: "transparent",
	};

	return (
		<>
			<Label text={t("download", "Download")}>
				{!loading ? (
					<div>
						<MouseLabel.Trigger
							label={
								disabled && t("enableBounceAndMaster", "Make a new render to enable bounce and master!")
							}
						>
							<Button
								style={disabled ? disabledStyles : {}}
								transparent
								size="small"
								onClick={() => !disabled && onClick("bounce")}
							>
								{t("bounce", "Bounce")}
							</Button>
						</MouseLabel.Trigger>
						<MouseLabel.Trigger
							label={
								disabled && t("enableBounceAndMaster", "Make a new render to enable bounce and master!")
							}
						>
							<Button
								style={disabled ? disabledStyles : {}}
								transparent
								size="small"
								onClick={() => !disabled && onClick("master")}
							>
								{t("master", "Master")}
							</Button>
						</MouseLabel.Trigger>
						<Button transparent size="small" onClick={midi}>
							{t("midi", "MIDI")}
						</Button>
						<Button transparent size="small" onClick={txt}>
							{t("txt", "TXT")}
						</Button>
					</div>
				) : (
					<Flex>
						<RotateIcon size={16} animation="rotate" />
						<Text style={{ marginLeft: 10 }}>
							{t("loadingFileWithCount", "Loading file {{count}}", { count })}
						</Text>
					</Flex>
				)}
			</Label>
		</>
	);
}

export default memo(Download);
