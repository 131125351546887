import React, { memo, useCallback } from "react";

import { useTool, setTool } from "../../state/tool";
import { useProductionId } from "../../state/productionId";

import Button from "./Button";

function Tool({ tool, disabled, ...props }) {
	const currentTool = useTool();
	const productionId = useProductionId();

	const onMouseDown = useCallback(({ button }) => button === 0 && setTool(() => tool), [tool]);

	return (
		<Button
			active={currentTool === tool}
			disabled={disabled || !productionId}
			onMouseDown={onMouseDown}
			onTouchStart={onMouseDown}
			{...props}
		/>
	);
}

export default memo(Tool);
