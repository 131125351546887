import { saveAs } from "file-saver";

import List from "astrid-components/lib/components/Data/List";
import Button from "astrid-components/lib/components/Inputs/Button";
import Flex from "astrid-components/lib/components/Layout/Flex";
import MouseLabel from "astrid-components/lib/components/Modules/MouseLabel";
import Text from "astrid-components/lib/components/Text/Text";
import msToTime from "astrid-helpers/src/msToTime";

import useText from "../../hooks/useText";

function Part({ file }) {
	const t = useText();

	const { start, stop, bounce, master } = file.data;

	const downloadMaster = (e) => {
		e.stopPropagation();

		saveAs(master.url, file.id);
	};

	const downloadBounce = (e) => {
		e.stopPropagation();

		saveAs(bounce.url, file.id);
	};

	return (
		<List.Item>
			<Flex height={45} padding="0 10px 0 20px">
				<Text fontWeight={600}>
					{msToTime(start, false)} - {msToTime(stop, false)}
				</Text>
				<Flex>
					<MouseLabel.Trigger label={t("downloadMaster", "Download master")}>
						<Button
							disabled={!master?.url}
							transparent
							icon
							onClick={downloadMaster}
							size="tiny"
							textSize="small"
						>
							{t("master", "Master")}
						</Button>
					</MouseLabel.Trigger>

					<MouseLabel.Trigger label={t("downloadBounce", "Download bounce")}>
						<Button
							disabled={!bounce?.url}
							transparent
							onClick={downloadBounce}
							size="tiny"
							textSize="small"
						>
							{t("bounce", "Bounce")}
						</Button>
					</MouseLabel.Trigger>
				</Flex>
			</Flex>
		</List.Item>
	);
}

export default Part;
