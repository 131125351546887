import React, { memo } from "react";

import { useSelectedWord } from "../../state/word";

import useWord from "../../hooks/useWord";
import useClip from "../../hooks/useClip";

import Word from "./Word";

function Words() {
	const selected = useSelectedWord();
	const word = useWord(selected && selected[0]);
	const clip = useClip(word && word.clip);

	if (!word || !clip) return false;

	return <Word word={word} position={clip.toOutside(word.start)} />;
}

export default memo(Words);
