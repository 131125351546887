import { useMemo } from "react";

import { useStudioActive } from "../state/studio";
import { useRecorder } from "../state/permissions";
import { useProductionId } from "../state/productionId";

export default function useCanRecord() {
	const recorder = useRecorder();
	const productionId = useProductionId();
	const studioActive = useStudioActive();

	return useMemo(() => recorder && productionId && studioActive, [productionId, recorder, studioActive]);
}
