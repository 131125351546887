import createStore from "astrid-helpers/src/createStore";

import { clips } from "./clips";
import { onProductionId } from "./productionId";

export const [getListenStop, setListenStop, useListenStop, , resetListenStop] = createStore(0);
export const [getListenIndex, setListenIndex, useListenIndex, , resetListenIndex] = createStore(-1);
export const [getListenLength, setListenLength, useListenLength, , resetListenLength] = createStore(0);
export const [getListenClipIds, setListenClipIds, useListenClipIds, , resetListenClipIds] = createStore([]);

function update() {
	const sorted = clips.filter((clip) => clip.track === 1).sort((a, b) => a.position - b.position);
	const unlistened = sorted.filter((clip) => !clip.listened);

	if (getListenLength() !== unlistened.length) {
		setListenClipIds(sorted.map((clip) => clip.id));
		setListenLength(unlistened.length);
		setListenIndex((unlistened[0] ? sorted.indexOf(unlistened[0]) : sorted.length) - 1);
	}
}

onProductionId((id) => {
	if (id) {
		return () => {
			resetListenStop();
			resetListenIndex();
			resetListenLength();
			resetListenClipIds();
		};
	}
});

clips.events.on("update", update);
