import React, { memo, useEffect, useState } from "react";

import Label from "astrid-components/lib/components/Inputs/Label";
import Select from "astrid-components/lib/components/Inputs/Select";

import useText from "../../hooks/useText";
import i18n from "../../i18n";

function onChange(language) {
	i18n.changeLanguage(language);
}

function Input() {
	const t = useText();

	const [languages, setLanguages] = useState([]);

	useEffect(() => {
		i18n.services.backendConnector.backend.getLanguages((error, langs) =>
			setLanguages(
				Object.entries(langs).map(([language, { name, nativeName }]) => ({
					value: language,
					label: `${name} (${nativeName})`,
				})),
			),
		);
	}, []);

	return (
		<Label text={t("language", "Language")}>
			<Select
				value={i18n.language}
				options={languages}
				placeholder={t("selectLanguage", "Select language")}
				onChange={onChange}
			/>
		</Label>
	);
}

export default memo(Input);
