import Button from "astrid-components/lib/components/Inputs/Button";
import Modal from "astrid-components/lib/components/Modules/Modal";
import Header from "astrid-components/lib/components/Text/Header";

import useText from "../../hooks/useText";

export default function BrowserModal() {
	const t = useText();

	return (
		<>
			<Header>{t("unsupportedBrowser", "Unsupported browser detected!")}</Header>
			<p style={{ textAlign: "center" }}>
				{t(
					"switchToChrome",
					"This application is not compatible with your browser. If you experience issues while using the application, please consider switching to Chrome.",
				)}
			</p>
			<div>
				<Button color="positive" onClick={() => Modal.setContent()}>
					{t("ok", "OK")}
				</Button>
			</div>
		</>
	);
}
