import * as firebase from "./helpers/firebase";
import nudgeTimeline from "./helpers/nudgeTimeline";

import { timeline } from "./state/timeline";
import { recordings } from "./state/recordings";
import { getProductionId } from "./state/productionId";

window.adminTools = {
	call(func, args = {}) {
		(async () => console.log(await firebase.worker.call(func, args)))();
	},
	fixClips() {
		return firebase.worker.fixClips(getProductionId());
	},
	moveTimeline(dist) {
		return firebase.commit(nudgeTimeline(0, dist));
	},
	resetListen(listened = false) {
		return firebase.commit(recordings.documents.values().map((recording) => recording.update({ listened })));
	},
	replaceClips(id) {
		return firebase.commit(
			recordings.documents.values().map((recording) =>
				recording.update({
					source: `${id}/${recording.number}.wav`,
					fill: `${id}/${recording.number}-fill.svg`,
					version: 3,
				}),
			),
		);
	},
	triggerRecording(number) {
		firebase.worker.call("triggerRecording", { productionId: getProductionId(), number });
	},
	batchRecordings() {
		firebase.worker.call("batchRecordings", { productionId: getProductionId() });
	},
	speech(number) {
		firebase.worker.call("speech", { productionId: getProductionId(), number });
	},
	netsuiteItems() {
		(async () => console.log(await firebase.worker.call("getStorytelNetsuiteItems")))();
	},
	compress(number) {
		firebase.worker.call("compressRecording", { productionId: getProductionId(), number });
	},
	encodeParts(productionId) {
		firebase.worker.call("encodeParts", { productionId });
	},
	encodePart(productionId, partId) {
		firebase.worker.call("encodePart", { productionId, partId });
	},
	batchMarkers(start = 0) {
		return firebase.commit(
			timeline.update(
				Object.entries(timeline.markers)
					.filter(([, time]) => time * 1000 >= start)
					.reduce((data, [id, time]) => {
						data[`markers.${id}`] = time / 1000;
						return data;
					}, {}),
			),
		);
	},
};
