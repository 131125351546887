import { audioBufferFromURL } from "astrid-helpers/src/audioBuffer";
import msToDateTime from "astrid-helpers/src/msToDateTime";

import * as peaks from "../helpers/peaks";
import * as silences from "../helpers/silences";
import { getPrecording, getPrecordingValue } from "../state/precordings";

import Document from "./document";

export default class Recording extends Document {
	get web() {
		return this.data.web;
	}

	get line() {
		return this.data.line;
	}

	get fill() {
		return this.data.fill;
	}

	get number() {
		return this.data.number;
	}

	get source() {
		return this.data.source;
	}

	get studio() {
		return this.data.studio;
	}

	get version() {
		return this.data.version;
	}

	get error() {
		return this.data.error || false;
	}

	get precording() {
		return getPrecording(this.id);
	}

	get loading() {
		return !this.source && !this.error;
	}

	get name() {
		return this.error ? "Saknar ljudfil" : this.filename || this.id;
	}

	get length() {
		return this.data.length || getPrecordingValue(this.id, "length") || 0;
	}

	get created() {
		return this.data.created && this.data.created.seconds && msToDateTime(this.data.created.seconds * 1000);
	}

	get url() {
		return this.data.compressed ? this.data.compressed.url : this.data.url;
	}

	get buffer() {
		return audioBufferFromURL(this.url);
	}

	get peaks() {
		return peaks.from(this);
	}

	get silences() {
		return silences.from(this);
	}

	async findSilence(position) {
		return (await this.silences).findSilence(position);
	}

	async findClosestSilence(position) {
		return (await this.silences).findClosestSilence(position);
	}

	load() {
		return this.buffer;
	}
}
