import { useMemo } from "react";

import { usePauses } from "../state/pauses";
import { usePauseFilter } from "../state/pauseFilter";

export default function useFilteredPauses(search) {
	const pauses = usePauses(true);
	const filter = usePauseFilter();

	return useMemo(
		() => pauses.filter((pause) => pause.text.includes(search) && filter.includes(pause.type)),
		[pauses, search, filter],
	);
}
