import React from "react";

import Timeline from "astrid-components/lib/components/Audio/Timeline";

import useText from "../../hooks/useText";
import { useEdited } from "../../state/edited";

export default function Edited() {
	const t = useText();
	const editedTime = useEdited();

	return <Timeline.Cursor position={editedTime}>{t("edited", "Edited")}</Timeline.Cursor>;
}
