import Timeline from "astrid-components/lib/components/Audio/Timeline";
import createStore from "astrid-helpers/src/createStore";

import { clips } from "./clips";
import { timeline } from "./timeline";

export const [getScopeClips, setScopeClips, useScopeClips, onScopeClips] = createStore({
	scope: Timeline.getScope(),
	clips: [],
});

function update() {
	if (timeline.loaded) {
		const scope = Timeline.getScope();

		setScopeClips({
			scope,
			clips: clips.scope(scope),
		});
	}
}

clips.events.on("update", update);
Timeline.onScope(update);
