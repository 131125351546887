import createStore from "astrid-helpers/src/createStore";

import { onProductionId } from "./productionId";

export const [getProductionLink, setProductionLink, useProductionLink, onProductionLink] = createStore("");

onProductionId((id) => {
	if (id) {
		setProductionLink(`${window.location.origin.replace("studio.", "")}/production/${id}`);
	}
});
