import createStore from "astrid-helpers/src/createStore";

export const [getPauses, setPauses, usePauses, onPauses] = createStore([]);

export function isPause(length) {
	return length >= 2800 && length <= 3200;
}

export function isChapter(length) {
	return length >= 4800 && length <= 5200;
}
