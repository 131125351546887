import React, { memo, useCallback, useMemo } from "react";
import { useDrag } from "react-use-gesture";
import { useDebouncedCallback } from "use-debounce";

import ArrowIcon from "astrid-components/lib/components/Assets/Icons/Arrow";
import CopyIcon from "astrid-components/lib/components/Assets/Icons/Copy";
import FileIcon from "astrid-components/lib/components/Assets/Icons/File";
import TrashIcon from "astrid-components/lib/components/Assets/Icons/Trash";
import WordIcon from "astrid-components/lib/components/Assets/Icons/Word";
import Pdf from "astrid-components/lib/components/Assets/Pdf";
import List from "astrid-components/lib/components/Data/List";
import Button from "astrid-components/lib/components/Inputs/Button";
import Input from "astrid-components/lib/components/Inputs/Input";
import Flex from "astrid-components/lib/components/Layout/Flex";
import Popup from "astrid-components/lib/components/Modules/Popup";
import Text from "astrid-components/lib/components/Text/Text";
import { push } from "astrid-hooks/src/useHistory";
import useTemp from "astrid-hooks/src/useTemp";

import * as firebase from "../../helpers/firebase";
import selectMarker from "../../helpers/selectMarker";
import useText from "../../hooks/useText";
import { setSidebar } from "../../state/sidebar";

const stopPropagation = ({ event }) => event.stopPropagation();

function Marker({ marker }) {
	const t = useText();
	const pages = Pdf.usePages().length;
	const [page, setPage] = useTemp(marker.page);

	const onDrag = useDrag(stopPropagation);

	const labels = useMemo(
		() => ({
			right: t("right", "Right word(s)"),
			wrong: t("wrong", "Wrong word(s)"),
			sentence: t("sentence", "Sentence(s)"),
			description: t("description", "Description"),
		}),
		[t],
	);

	const onClickUp = useCallback(
		() => push(t("editMarker", "Edit marker"), firebase.commit(marker.update({ page: page + 1 }))),
		[marker, page, t],
	);

	const onClickDown = useCallback(
		() => push(t("editMarker", "Edit marker"), firebase.commit(marker.update({ page: page - 1 }))),
		[marker, page, t],
	);

	const onClickWord = useCallback(() => {
		Pdf.setSelection({ text: marker.wrong });
		setSidebar(1);
	}, [marker]);

	const onClickCopy = useCallback(() => {
		if (marker.copy()) {
			push(t("copyFromScript", "Copy from script"), firebase.commit(marker));
		}
	}, [marker, t]);

	const onClickDelete = useCallback(() => {
		push(t("deleteMarker", "Delete marker"), firebase.commit(marker.remove()));
		selectMarker();
	}, [marker, t]);

	const onChange = useDebouncedCallback(
		(key, value) => push(t("editMarker", "Edit marker"), firebase.commit(marker.update({ [key]: value }))),
		500,
	);

	const keys = marker.fields.map(({ key }) => key);

	return (
		<div style={{ paddingLeft: 62 }} {...onDrag()}>
			<div style={{ paddingRight: 20 }}>
				{marker.fields.map(({ as, key, label }) => (
					<Flex key={key} paddingBottom={10}>
						{key === "sentence" ? (
							<Text>{marker[key]}</Text>
						) : (
							<Input
								as={as}
								size="small"
								placeholder={labels[key]}
								defaultValue={marker[key]}
								style={{
									flex: 1,
									height: "auto",
									lineHeight: "16px",
									padding: "8px 10px",
									fontFamily: "inherit",
									color: "black",
								}}
								onChange={({ target }) => onChange(key, target.value)}
							/>
						)}
					</Flex>
				))}
			</div>
			<Flex style={{ padding: "0 10px 20px 0" }}>
				<Flex>
					<Input
						size="small"
						value={page}
						placeholder={t("scriptPage", "Script page")}
						style={{ width: 60 }}
						onChange={({ target }) => {
							setPage(parseInt(target.value) || 1);
							onChange("page", parseInt(target.value) || 1);
						}}
					/>
					<div style={{ width: 10 }} />
					<Button transparent size="small" disabled={page >= pages} onClick={onClickUp}>
						<ArrowIcon size={14} style={{ transform: "rotate(180deg)" }} />
					</Button>
					<Button transparent size="small" disabled={page === 1} onClick={onClickDown}>
						<ArrowIcon size={14} />
					</Button>
				</Flex>
				<Flex marginLeft="auto">
					{keys.includes("wrong") && (
						<Button transparent size="small" onClick={onClickWord}>
							<WordIcon size={14} />
						</Button>
					)}
					{keys.includes("sentence") && (
						<Button transparent size="small" onClick={onClickCopy}>
							<CopyIcon size={14} />
						</Button>
					)}
					<Popup.Trigger
						align="bottom left"
						trigger={
							<Button transparent size="small">
								<FileIcon size={14} />
							</Button>
						}
					>
						<List>
							<List.Item first>
								<Flex justifyContent="flex-start" paddingRight={10}>
									<List.Header>{t("identity", "ID")}</List.Header>
									<List.Text>{marker.id}</List.Text>
								</Flex>
							</List.Item>
							{marker.created && (
								<List.Item>
									<Flex justifyContent="flex-start" paddingRight={10}>
										<List.Header>{t("created", "Created")}</List.Header>
										<List.Text>{marker.created}</List.Text>
									</Flex>
								</List.Item>
							)}
							{marker.createdBy && (
								<List.Item>
									<Flex justifyContent="flex-start" paddingRight={10}>
										<List.Header>{t("by", "By")}</List.Header>
										<List.Text>{marker.createdBy.email}</List.Text>
									</Flex>
								</List.Item>
							)}
						</List>
					</Popup.Trigger>
					<Button transparent size="small" color="negative" disabled={marker.staged} onClick={onClickDelete}>
						<TrashIcon size={14} />
					</Button>
				</Flex>
			</Flex>
		</div>
	);
}

export default memo(Marker);
