import { getChapters } from "../state/chapters";
import { clips } from "../state/clips";
import { getProduction } from "../state/production";
import { getProductionId } from "../state/productionId";

import * as firebase from "./firebase";
import syncChapters from "./syncChapters";

export function modify(modified) {
	const production = getProduction();

	if (modified < production.render.modified) {
		return production.update({ "render.modified": modified });
	}
}

export function render(length, push, status) {
	const clip = clips.atPosition(length) || clips.toLeft(length);

	if (clip) {
		const chapters = getChapters();
		const production = getProduction();

		length = clip.toOutside(clip.end);

		firebase.commit(
			production.update({
				"render.time": length,
				"render.date": Date.now(),
				"render.version": "1.2.0",
				"render.modified": length,
			}),
		);

		if (chapters) {
			syncChapters();
		}

		return firebase.worker.call("render-parts", {
			push,
			length,
			status,
			version: 3,
			productionId: production.id,
		});
	}
}

export function abort() {
	return firebase.worker.call("render-abort", {
		productionId: getProductionId(),
	});
}
