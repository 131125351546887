import Button from "astrid-components/lib/components/Inputs/Button";
import Modal from "astrid-components/lib/components/Modules/Modal";
import Header from "astrid-components/lib/components/Text/Header";

import useText from "../../hooks/useText";

export default function RenderModal({ render, time, onConfirm }) {
	const t = useText();

	return (
		<Header>
			{render
				? t("doYouWantToRender", "Do you want to render until {{time}}?", { time })
				: t("doYouWantToRenderAndPark", "Do you want to render until {{time}} and upload to parking?", {
						time,
				  })}
			<div style={{ display: "flex", gap: 5, justifyContent: "center", marginTop: 15 }}>
				<Button color="positive" onClick={onConfirm}>
					{render ? t("yesRender", "Yes - Render") : t("yesRenderAndPark", "Yes - Render and park")}
				</Button>
				<Button color="positive" onClick={() => Modal.setContent()}>
					{t("no", "No")}
				</Button>
			</div>
		</Header>
	);
}
