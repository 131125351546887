import { createEntityAdapter } from "@reduxjs/toolkit";
import produce from "immer";

import createStore from "astrid-helpers/src/createStore";

export default function createCollection({ sortComparer } = {}) {
	const adapter = createEntityAdapter({ sortComparer });

	const selectors = adapter.getSelectors();

	const [getState, setState, useState, onState, resetState] = createStore(adapter.getInitialState());

	const useAll = () => selectors.selectAll(useState());
	const useById = (id) => selectors.selectById(useState(), id);

	const getAll = () => selectors.selectAll(getState());
	const getById = (id) => selectors.selectById(getState(), id);

	const setSnapshot = (snapshot) =>
		setState(
			produce((state) => {
				adapter.upsertMany(state, snapshot);
				adapter.removeMany(
					state,
					snapshot.filter(({ type }) => type === "removed").map(({ id }) => id),
				);
			}),
		);

	return { getState, onState, useAll, useById, getAll, getById, setSnapshot, resetState };
}
