import Peer from "simple-peer/simplepeer.min.js";
import "webrtc-adapter";

import getIceServers from "astrid-config/src/iceServers";

import eventTarget from "./eventTarget";

export default function createPeer({ initiator = false, port = 3478, stream }) {
	let destroyed = false;

	const events = eventTarget();
	const messages = eventTarget();

	const sentSignals = [];

	const peer = new Peer({
		stream,
		initiator,
		trickle: false,
		config: {
			iceServers: getIceServers(port),
		},
	});

	console.log("Peer", peer._id, "created");

	events.emit("connecting", peer);

	const destroy = () => {
		peer.destroy();
	};

	const sendSignal = (signal) => {
		if (!destroyed && !sentSignals.includes(signal.signal)) {
			sentSignals.push(signal.signal);

			if (signal.signal) {
				peer.signal(JSON.parse(signal.signal));
			}
		}
	};

	const sendMessage = (key, value = null, data = {}) => {
		if (!destroyed) {
			if (typeof key !== "string") {
				peer.send(key);
			} else {
				peer.send(JSON.stringify({ key, value, data }));
			}
		}
	};

	peer.on("data", (json) => {
		const { key, value, data } = JSON.parse(json);

		messages.emit(key, value, data);
	});

	peer.on("connect", () => {
		console.log("Peer", peer._id, "connected");

		peer._channel.onmessage = ({ data }) => {
			if (typeof data === "string") {
				const { key, value } = JSON.parse(data);
				messages.emit(key, value);
			} else {
				messages.emit("data", data);
			}
		};

		events.emit("connect", peer);
	});

	peer.on("iceStateChange", (state) => {
		console.log("Peer", peer._id, "iceStateChange", state);

		if (state === "disconnected") {
			destroy();
		}
	});

	peer.on("close", () => {
		console.log("Peer", peer._id, "closed");

		events.emit("close", peer);

		events.clear();
		messages.clear();
	});

	peer.on("error", (e) => {
		console.error("Peer", peer._id, "error", e);

		events.emit("error", peer, e);
	});

	peer.on("stream", (stream) => {
		events.emit("stream", stream);
	});

	peer.on("signal", (data) => {
		console.log("Peer", "signal", data);

		events.emit("signal", JSON.stringify(data));
	});

	return {
		...events,

		id: peer._id,

		destroy,

		sendSignal,
		sendMessage,

		messages,
	};
}
