import Pdf from "astrid-components/lib/components/Assets/Pdf";
import Timeline from "astrid-components/lib/components/Audio/Timeline";
import createStore from "astrid-helpers/src/createStore";
import timeToMs from "astrid-helpers/src/timeToMs";

import * as firebase from "../helpers/firebase";
import params from "../helpers/params";
import { updatePosition } from "../services/timeline";

import { onFlattenedClips } from "./flattenedClips";
import { getRecorder } from "./permissions";
import { getProduction } from "./production";

export const [getDuration, setDuration, useDuration, onDuration] = createStore(0);

let prevProductionDuration;
let prevProductionId;

onFlattenedClips((flattened) => {
	if (getRecorder() && flattened.length > 0) {
		const prevDuration = getDuration();
		const production = getProduction();

		const { page, position, length } = flattened[flattened.length - 1];
		const duration = position + length;

		if (prevDuration !== duration) {
			setDuration(duration);

			// update recorded progress bar
			if (production.id === prevProductionId) {
				firebase.commit(production.updateProgress("recorded", duration));

				// restore edited progress bar if duration reduces
				if (duration < prevDuration) {
					firebase.commit(production.restoreProgress("edited", duration));
				}
			}
		}

		const timelineDuration = Timeline.minDuration + duration;

		if (Timeline.getDuration() !== timelineDuration) {
			Timeline.setDuration(timelineDuration);
		}

		// jump to correnct page and location when loading a new production
		if (production.id !== prevProductionId && duration !== prevProductionDuration) {
			prevProductionId = production.id;
			prevProductionDuration = duration;

			updatePosition(params.get("time") ? timeToMs(params.get("time")) : duration);
			Timeline.resetSize();
			Pdf.setPage(page);
		}
	}
});
