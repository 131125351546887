import createStore from "astrid-helpers/src/createStore";

import { markers } from "./markers";
import { getRole, onRole } from "./permissions";
import { getUid } from "./user";

export const [getVisibleMarkers, setVisibleMarkers, useVisibleMarkers, onVisibleMarkers] = createStore([]);

function updateVisibleMarkers() {
	const uid = getUid();
	const role = getRole();

	setVisibleMarkers(
		markers.sorted.filter(
			(marker) => ((role && role !== "reader") || marker.staged) && (role !== "proofer" || marker.canView(uid)),
		),
	);
}

markers.events.on("update", (c, trickle) => trickle && updateVisibleMarkers());
onRole(updateVisibleMarkers);
