import createStore from "astrid-helpers/src/createStore";

import { restartRecorder } from "../services/recorder";

export const [getFlattenedClips, setFlattenedClips, useFlattenedClips, onFlattenedClips] = createStore([]);

onFlattenedClips((flattenedClips) => {
	if (flattenedClips.length > 0) {
		return restartRecorder();
	}
});
