import { audioBufferFromURL } from "astrid-helpers/src/audioBuffer";
import context from "astrid-helpers/src/audioContext";
import createAudioPlayer from "astrid-helpers/src/audioPlayer";

import { isAction, onAction } from "../state/action";
import { setBuffering } from "../state/buffering";
import { getFlattenedClips } from "../state/flattenedClips";
import { parts } from "../state/parts";
import { getRole } from "../state/permissions";
import { recordings } from "../state/recordings";
import { getShowRendered } from "../state/showRendered";
import { isStudioActive } from "../state/studio";

import { stopRecorder } from "./recorder";
import { updatePosition } from "./timeline";

const player = createAudioPlayer(context);

player.connect();

function getClipsChunk(position, duration) {
	return getFlattenedClips()
		.filter((clip) => position < clip.position + clip.length && clip.position < position + duration)
		.map((clip) => ({
			...clip,
			position: clip.position - position,
			getBuffer: () => recordings.get(clip.number).buffer,
		}));
}

function getPartsChunk(position, duration) {
	return parts
		.filter((part) => position < part.stop && part.start < position + duration)
		.map((part) => {
			const seek = Math.max(0, position - part.start);

			return {
				id: part.id,
				getBuffer: () => audioBufferFromURL(part.url),
				position: Math.max(0, part.start - position),
				start: Math.max(0, seek),
				length: Math.max(0, part.length - seek),
			};
		});
}

export function getChunk(position, duration = 3 * 60000) {
	if (isAction("record")) return [];

	const proofer = getRole() === "proofer";
	const showRendered = getShowRendered();

	const method = proofer || showRendered ? getPartsChunk : getClipsChunk;

	return method(position, duration);
}

function loadChunk(chunk) {
	return Promise.all(chunk.map(async (next) => ({ ...next, buffer: await next.getBuffer() })));
}

function getChunkLength(chunk) {
	return Math.max(0, ...chunk.map((next) => next.position + next.length));
}

function startPlayer(position, duration = 10000) {
	let stopped = false;

	const register = [];

	setBuffering(true);

	const increment = (time) => !stopped && updatePosition(position + time);

	(async () => {
		// resume audio context to enable playback
		await player.resume();

		while (!stopped) {
			// get the next chunk from the current position
			const chunk = getChunk(position, duration).filter(({ id }) => !register.includes(id));

			if (chunk.length > 0) {
				setBuffering(true);

				register.push(...chunk.map(({ id }) => id));

				// load audio buffers into memory
				const loadedChunk = await loadChunk(chunk);

				if (!stopped) {
					const startPosition = position;

					setBuffering(false);

					// preload next chunk to prevent buffering
					loadChunk(getChunk(getChunkLength(chunk), duration)).catch((error) => console.error(error));

					// play current chunk
					const sounds = player.playSounds(player.createSounds(loadedChunk));

					// sync timeline playhead with current time
					sounds.on("time", (time) => increment(time));

					// wait for chunk to have finished playing or to have stopped
					const time = await sounds.until("stop");

					// start next chunk from current position
					position = startPosition + time;
				}
			} else {
				// stop looping if there is no more chunks
				stopRecorder();
			}
		}
	})();

	return () => {
		stopped = true;
		setBuffering(false);
		player.stopSounds();
	};
}

onAction(({ type, position }) => {
	if (type === "start") {
		updatePosition(position);

		if (!isStudioActive()) {
			return startPlayer(position);
		}
	}
});
