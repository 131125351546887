import createStore from "astrid-helpers/src/createStore";

import { getSelectedPause, onSelectedPause, setSelectedPause } from "./selectedPause";

export const [getSelectedMarker, setSelectedMarker, useSelectedMarker, onSelectedMarker] = createStore(false);

export function useIsSelectedMarker(id) {
	const selected = useSelectedMarker();

	return selected && selected[0] === id;
}

onSelectedPause((selected) => {
	if (selected !== false && getSelectedMarker() !== false) setSelectedMarker(false);
});

onSelectedMarker((selected) => {
	if (selected !== false && getSelectedPause() !== false) setSelectedPause(false);
});
