import * as Comlink from "comlink";

import Worker from "../workers/clips.worker?worker";

// eslint-disable-next-line no-undef
export const worker = Comlink.wrap(new Worker());

export async function flatten(...args) {
	return await worker.flatten(...args);
}
