import React, { memo, useCallback } from "react";

import Timeline from "astrid-components/lib/components/Audio/Timeline";
import useTemp from "astrid-hooks/src/useTemp";

import * as firebase from "../../helpers/firebase";
import useIsRole from "../../hooks/useIsRole";
import { clips } from "../../state/clips";

function Word({ word, position }) {
	const proofer = useIsRole("proofer");
	const [tempLength, setTempLength] = useTemp(word.length);
	const [tempPosition, setTempPosition] = useTemp(position);

	const onMove = useCallback(
		(side, delta) => {
			if (side === 0) {
				setTempLength(word.length - delta);
				setTempPosition(position + delta);
			} else {
				setTempLength(word.length + delta);
			}
		},
		[setTempLength, setTempPosition, word.length, position],
	);

	const onRelease = useCallback(() => {
		const clip = clips.atPosition(tempPosition + tempLength / 2);

		if (clip) {
			const key = `contributions.${word.choice}`;

			firebase.commit(
				word.update({
					[`${key}.length`]: tempLength,
					[`${key}.start`]: Math.max(clip.start, clip.toInside(tempPosition)),
				}),
			);
		}
	}, [tempLength, tempPosition, word]);

	return (
		<Timeline.Range
			color="positive"
			length={tempLength}
			position={tempPosition}
			expand={proofer ? "bottom" : true}
			onMove={onMove}
			onRelease={onRelease}
		/>
	);
}

export default memo(Word);
