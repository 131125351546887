import Cache from "lru-cache";
import { useCallback, useMemo } from "react";

import useAsync from "./useAsync";

const defaultCache = new Cache({ max: 100 });

export default function useAsyncCache(key, fn, cache = defaultCache) {
	const cached = useMemo(() => cache.get(key), [cache, key]);

	const callback = useCallback(async () => {
		const value = await fn();
		cache.set(key, value);
		return value;
	}, [cache, fn, key]);

	const value = useAsync(!cached && callback);

	return cached || value;
}
