import React, { memo, useCallback, useMemo } from "react";

import Timeline from "astrid-components/lib/components/Audio/Timeline";
import useMouseTouch from "astrid-hooks/src/useMouseTouch";

import useSelectionRange from "../../hooks/useSelectionRange";
import { startEdit } from "../../services/recorder";
import { setTool } from "../../state/tool";

function EditTool({ x, y }) {
	const length = useMemo(() => Math.floor(y * 10) + 1, [y]);

	const [range] = useSelectionRange(x, [0, length]);

	const disabled = y < 0 || y > 1 || !range;

	const onMouseUp = useCallback(() => {
		setTool();

		if (!disabled) {
			startEdit(range);
		}
	}, [disabled, range]);

	useMouseTouch("up", onMouseUp, window, true);

	if (disabled) return false;

	return <Timeline.Range position={range[0]} length={range[1] - range[0]} />;
}

export default memo(EditTool);
