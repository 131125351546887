import { clips } from "../state/clips";
import { markers } from "../state/markers";
import { getProductionId } from "../state/productionId";

import Document from "./document";

export default class Timeline extends Document {
	constructor() {
		super("timeline");
		this.clear();
	}

	get path() {
		return `productions/${getProductionId()}/states/timeline`;
	}

	get clips() {
		return this.data.clips;
	}

	get markers() {
		return this.data.markers;
	}

	set snapshot(snapshot) {
		if (snapshot) {
			this.loaded = true;

			if (snapshot.clips) {
				this.data.clips = snapshot.clips;
				clips.loaded && clips.update(false);
			}

			if (snapshot.markers) {
				this.data.markers = snapshot.markers;
				markers.loaded && markers.update(false);
			}
		}
	}

	set(type, id, position) {
		return this.update({ [`${type}.${id}`]: position });
	}

	remove(type, id) {
		return this.set(type, id);
	}

	clear() {
		super.clear();

		this.loaded = false;
		this.data = { clips: {}, markers: {} };
	}
}
