import React, { memo } from "react";

import Pdf from "astrid-components/lib/components/Assets/Pdf";
import Timeline from "astrid-components/lib/components/Audio/Timeline";
import { audioBufferFromBlob } from "astrid-helpers/src/audioBuffer";
import clamp from "astrid-helpers/src/clamp";
import map from "astrid-helpers/src/map";
import { push } from "astrid-hooks/src/useHistory";

import disableAutoScroll from "../../helpers/disableAutoScroll";
import * as firebase from "../../helpers/firebase";
import useIsRole from "../../hooks/useIsRole";
import { startRecorder, stopRecorder } from "../../services/recorder";
import { jumpToPosition } from "../../services/timeline";
import { isAction } from "../../state/action";
import { useBuffering } from "../../state/buffering";
import { clips } from "../../state/clips";
import { getRecorder, useEditor, useRecorder } from "../../state/permissions";
import { getProductionId } from "../../state/productionId";
import { recordings } from "../../state/recordings";
import { useShowRendered } from "../../state/showRendered";
import { getTrack } from "../../state/track";

import Edited from "./Edited";
import Lasso from "./Lasso";
import Markers from "./Markers";
import Parts from "./Parts";
import Preroll from "./Preroll";
import Spaces from "./Spaces";
import Tool from "./Tool";
import Tracks from "./Tracks";
import Words from "./Words";

function onClick(position) {
	jumpToPosition(position);
}

function onDoubleClick(position) {
	if (isAction("start")) {
		stopRecorder();
	} else if (isAction("stop")) {
		startRecorder({ position });
	}
}

function onDragOver(e) {
	e.preventDefault();
	// onMouseMove(e);
}

function onMove() {
	if (getRecorder()) {
		disableAutoScroll();
	}
}

async function onDrop(e) {
	e.preventDefault();

	const { pageX } = e;
	const productionId = getProductionId();

	if (productionId) {
		const [file] = e.dataTransfer.files;
		const audio = await audioBufferFromBlob(file);

		if (audio) {
			const size = Timeline.getSize();
			const offset = Timeline.getOffset();
			const number = recordings.nextNumber;
			const length = audio.duration * 1000;
			const { left, width } = Timeline.getRect();

			const recording = recordings.create(number, {
				length,
				filename: file.name,
			});

			const clip = clips.create(number, {
				start: 0,
				end: length,
				track: getTrack(),
				pageIn: Pdf.getPage() || 1,
				pageOut: Pdf.getPage() || 1,
				position: map(pageX - left, 0, width, offset, offset + size),
			});

			firebase.worker.upload(`${productionId}/${number}.wav`, file, {
				contentType: "audio/wav",
				customMetadata: {
					studio: "Importerad",
				},
			});

			push("Ladda upp fil", firebase.commit([recording, clip]));
		}
	}
}

function onKeyDown(e) {
	switch (e.code) {
		// Zoom in/out with arrow keys
		case "ArrowUp":
		case "ArrowDown":
			Timeline.setSize((size) => {
				const zoom = e.code === "ArrowUp" ? 1.075 : 0.93;
				const newSize = clamp(size * zoom, 1, 1000 * 60 * 3);
				const sizeDiff = size - newSize;
				const percentageToPosition = (Timeline.getPosition() - Timeline.getOffset()) / size;

				Timeline.setOffset((offset) => offset + sizeDiff * percentageToPosition);

				return newSize;
			});
			break;

		// Move left/right with arrow keys
		case "ArrowLeft":
		case "ArrowRight":
			Timeline.setOffset((offset) => {
				const direction = e.code === "ArrowRight" ? 0.0333 : -0.0333;

				return Math.max(0, offset + Timeline.getSize() * direction);
			});
			break;

		default:
	}
}

function TimelineContainer() {
	const editor = useEditor();
	const recorder = useRecorder();
	const buffering = useBuffering();
	const proofer = useIsRole("proofer");
	const showRendered = useShowRendered();

	const showParts = proofer || showRendered;

	return (
		<Timeline
			tabIndex={-1}
			min={recorder ? 1 : 1000 * 10}
			onDrop={onDrop}
			onMove={onMove}
			onDragOver={onDragOver}
			onDoubleClick={onDoubleClick}
			onClick={(position, element) => {
				element.focus();
				onClick(position);
			}}
			onKeyDown={onKeyDown}
		>
			<Timeline.Scope value={2}>
				<Timeline.Ruler />
				<Timeline.Range position={0} length={2000} />
				{showParts ? (
					<Parts />
				) : (
					recorder && (
						<>
							<Tracks />
							<Spaces />
						</>
					)
				)}
				{proofer && <Words />}
				{editor && <Edited />}
				<Markers />
				<Lasso />
				<Timeline.MouseCursor />
				{recorder && <Preroll />}
				<Tool />
				<Timeline.PositionCursor buffering={buffering} />
				{proofer && (
					<>
						<Timeline.Proofed />
					</>
				)}
				{(showRendered || proofer) && <Timeline.Locked />}
			</Timeline.Scope>
		</Timeline>
	);
}

export default memo(TimelineContainer);
