import MIDI from "midi-writer-js";

import { markers } from "../state/markers";
import { getProduction } from "../state/production";

export default function midi() {
	let ticked = 0;

	const track = new MIDI.Track();

	markers
		.filter((marker) => !marker.checked)
		.forEach((marker) => {
			let tick = Math.round(((128 * 120) / 60) * marker.time) - ticked;

			if (tick < 0) tick = 0;

			ticked = ticked + tick;

			track.addEvent(new MIDI.NoteEvent({ pitch: ["C4"], duration: "T" + tick }));
			track.addMarker(marker.labelEn);
		});

	const write = new MIDI.Writer([track]);
	const link = document.createElement("a");

	link.download = getProduction().title + " - korr.midi";
	link.href = write.dataUri();

	link.dispatchEvent(new MouseEvent("click", { bubbles: true, cancelable: true, view: window }));
}
