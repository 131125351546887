import React, { memo } from "react";

import Divider from "astrid-components/lib/components/Layout/Divider";
import Header from "astrid-components/lib/components/Text/Header";

import useText from "../../hooks/useText";

import Automation from "./Automation";
import Gate from "./Gate";
import Input from "./Input";
import Language from "./Language";
import Output from "./Output";
import Sync from "./Sync";

const header = {
	padding: "10px 26%",
};

const divider = {
	margin: "15px 15px 10px 26%",
};

function Settings() {
	const t = useText();

	return (
		<div
			style={{
				padding: 10,
			}}
		>
			<Language />
			<Divider style={divider} />
			<Header textSize="small" style={header}>
				{t("script", "Script")}
			</Header>
			<Sync />
			<Divider style={divider} />
			<Header textSize="small" style={header}>
				{t("audio", "Audio")}
			</Header>
			<Input />
			<Output />
			<Divider style={divider} />
			<Header textSize="small" style={header}>
				{t("gate", "Gate")}
			</Header>
			<Gate />
			<Divider style={divider} />
			<Automation />
			{/* <Theme /> */}
		</div>
	);
}

export default memo(Settings);
