import Pdf from "astrid-components/lib/components/Assets/Pdf/Pdf";
import createStore from "astrid-helpers/src/createStore";

import i18n from "../i18n";

import { onProductionId } from "./productionId";

export const [getShowPauses, setShowPauses, useShowPauses] = createStore(true);
export const [getShowScript, setShowScript, useShowScript] = createStore(true);
export const [getShowMarkers, setShowMarkers, useShowMarkers] = createStore(true);
export const [getMarkerFilter, setMarkerFilter, useMarkerFilter] = createStore([]);
export const [getResultsLength, setResultsLength, useResultsLength] = createStore(0);

export const markerFilterOptions = [
	{
		color: "negative",
		label: i18n.t("misreadMarker", "Misread"),
		value: ["edit", "missing", "script"],
	},
	{
		color: "negative",
		label: i18n.t("wrongMarker", "Mispronunciation"),
		value: ["wrong"],
	},
	{
		color: "negative",
		label: i18n.t("pauses", "Pauses"),
		value: ["shorter", "longer"],
	},
	{
		color: "default",
		label: i18n.t("memoMarker", "Memo"),
		value: ["memo"],
	},
	{
		color: "negative",
		label: i18n.t("noiseMarker", "Noise"),
		value: ["noise", "noisePause", "noiseContent"],
	},
	{
		color: "secondary",
		label: i18n.t("dimmedMarker", "Dimmed"),
		value: ["checked"],
	},
];

onProductionId((productionId) => {
	if (productionId) {
		setShowPauses(true);
		setShowScript(true);
		setShowMarkers(true);
		setMarkerFilter(markerFilterOptions.map(({ value }) => value));

		Pdf.setSearch("");
		Pdf.setSelection();
		Pdf.setSearchIndex();
		Pdf.setSearchResults([]);
	}
});
