import React, { memo, useState } from "react";

import Arrow from "astrid-components/lib/components/Assets/Icons/Arrow";

import { prev } from "../../helpers/listen";
import { useListenIndex } from "../../state/listen";
import { useStudioActive } from "../../state/studio";

import Button from "./Button";

function Prev() {
	const index = useListenIndex();
	const active = useStudioActive();

	const [loading, setLoading] = useState(false);

	const disabled = loading || active || index <= 0;

	const onClick = async () => {
		setLoading(true);
		await prev();
		setLoading(false);
	};

	return (
		<Button size="small" disabled={disabled} onClick={onClick}>
			<Arrow style={{ transform: "rotate(90deg)" }} />
		</Button>
	);
}

export default memo(Prev);
