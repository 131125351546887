import { useMemo } from "react";

import { closest } from "../helpers/silences";

import useSilences from "./useSilences";

export default function useClosestSilence(position) {
	const detections = useSilences();

	return useMemo(() => (position ? closest(detections, position) : undefined), [detections, position]);
}
