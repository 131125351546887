import context from "./audioContext";

const timeout = 5 * 60;

let seconds = 0;
let prev = context.currentTime;

function update() {
	const next = context.currentTime;
	const diff = next - prev;

	if (diff < timeout) {
		seconds += diff;
	}

	prev = next;
}

window.addEventListener("mousedown", update);
window.addEventListener("touchstart", update);

export function set(time) {
	seconds = time;
	prev = context.currentTime;
}

export function get() {
	return seconds;
}
