import React, { memo, useCallback, useMemo } from "react";

import Timeline from "astrid-components/lib/components/Audio/Timeline";
import markerTypes from "astrid-config/src/markerTypes";
import clamp from "astrid-helpers/src/clamp";
import { push } from "astrid-hooks/src/useHistory";
import useMouseTouch from "astrid-hooks/src/useMouseTouch";

import * as firebase from "../../helpers/firebase";
import jumpToMarker from "../../helpers/jumpToMarker";
import selectMarker from "../../helpers/selectMarker";
import useIsRole from "../../hooks/useIsRole";
import useSilenceAtPosition from "../../hooks/useSilenceAtPosition";
import useText from "../../hooks/useText";
import { translations } from "../../i18n";
import { clips } from "../../state/clips";
import { markers } from "../../state/markers";
import { setTool } from "../../state/tool";

function Marker({ x, y, types, proof }) {
	const t = useText();
	const proofer = useIsRole("proofer");
	const proofed = Timeline.useProofed();
	const { height } = Timeline.useRect();
	const silence = useSilenceAtPosition(x);

	const filteredTypes = clips.length ? types[silence ? 1 : 0] : [...new Set([...types[0], ...types[1]])];

	const index = useMemo(
		() => Math.floor(clamp(filteredTypes.length * y, 0, filteredTypes.length - 1)),
		[filteredTypes.length, y],
	);

	const notProofed = useMemo(() => proof && proofer && x > proofed, [proof, proofed, proofer, x]);

	const type = filteredTypes[index];
	const active = !notProofed && type && x && y > 0 && y < 1;

	const onMouseUp = useCallback(() => {
		setTool();

		if (active) {
			const marker = markers.create(type, x);
			push(t("createMarker", "Create marker"), firebase.commit(marker));
			selectMarker(marker.id, true);
			jumpToMarker(marker.id);
		}
	}, [active, t, type, x]);

	useMouseTouch("up", onMouseUp, window, true);

	if (!active) return false;

	return (
		<Timeline.Handle
			color={markerTypes[type].color}
			position={x}
			y={((index + 0.5) / filteredTypes.length) * height}
		>
			{translations.markerTypes[type]}
		</Timeline.Handle>
	);
}

export default memo(Marker);
