import Modal from "astrid-components/lib/components/Modules/Modal";

import Timeline from "../classes/timeline";
import Update from "../components/Update";
import { events, worker } from "../helpers/firebase";

import { onProductionId } from "./productionId";

export const timeline = new Timeline();

events.on("timeline", ({ data }) => {
	if (data) {
		if (data.recordings) {
			Modal.setContent(<Update />);
		} else {
			timeline.snapshot = data;
		}
	} else {
		worker.set(timeline.path, {});
	}
});

onProductionId((id) => {
	if (id) {
		worker.timeline(id);

		return () => timeline.clear();
	}
});
