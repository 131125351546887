import React, { memo } from "react";

import Pin from "astrid-components/lib/components/Assets/Icons/Pin";

import { restoreProofing } from "../../services/proofing";
import { useProofing } from "../../state/proofing";

import Button from "./Button";

function Proof() {
	const proofing = useProofing();

	return (
		<Button active={!proofing} onClick={restoreProofing}>
			<Pin animation={!proofing && "blink"} />
		</Button>
	);
}

export default memo(Proof);
