import createStore from "astrid-helpers/src/createStore";

import * as firebase from "../helpers/firebase";

import { onProductionId } from "./productionId";
import { getProfile, getUid } from "./user";

export const [getActiveUsers, setActiveUsers, useActiveUsers, onActiveUsers] = createStore({});

firebase.events.on("activeUsers", setActiveUsers);

onProductionId((id) => {
	if (id) {
		firebase.worker.activeUsers(id);

		const path = `productions/${id}/states/activeUsers`;

		const update = () => {
			const uid = getUid();
			const profile = getProfile();

			if (profile) {
				const { firstName, lastName, img } = profile;

				firebase.write([
					[
						"set",
						path,
						{
							[uid]: {
								img: img || null,
								name: `${firstName} ${lastName}`,
								timestamp: new Date(),
							},
						},
					],
				]);
			}
		};

		const interval = setInterval(update, 60 * 1000);

		update();

		return () => {
			clearInterval(interval);
		};
	}
});
