import Cache from "lru-cache";

import { worker } from "../helpers/firebase";

export const urls = new Cache({ max: 100 });

export default function storageURL(source) {
	if (source) {
		if (!urls.has(source)) {
			urls.set(source, worker.url(source));
		}

		return urls.get(source);
	}
}
