import Timeline from "astrid-components/lib/components/Audio/Timeline";

import loadClipAtPosition from "../helpers/loadClipAtPosition";
import { isAction } from "../state/action";
import { getAnimate, setAnimate } from "../state/animate";
import { getAutoScroll } from "../state/autoScroll";

import { startRecorder, stopRecorder } from "./recorder";

export function getPercentageOfPositionInView() {
	const size = Timeline.getSize();
	const offset = Timeline.getOffset();
	const position = Timeline.getPosition();

	return (position - offset) / size;
}

export function isPositionVisible() {
	const p = getPercentageOfPositionInView();

	return p > 0 && p < 1;
}

export function moveViewToPosition(animate = false) {
	if (animate && !getAnimate()) {
		setAnimate(true);
	}

	const size = Timeline.getSize();
	const position = Timeline.getPosition();
	Timeline.setOffset(Math.max(0, position - size * 0.2));

	if (getAnimate()) {
		setTimeout(() => setAnimate(false), 1000);
	}
}

export function autoAnimateViewToPosition() {
	if (getAutoScroll() && !getAnimate()) {
		const p = getPercentageOfPositionInView();

		if (p < 0 || p >= 0.9) {
			moveViewToPosition(true);
		}
	}
}

export function updatePosition(position, animate = true) {
	Timeline.setPosition(position);

	// Fixed playhead
	if (animate) {
		autoAnimateViewToPosition();
	}
}

export function jumpToPosition(position) {
	if (isAction("stop")) {
		updatePosition(position, false);
		loadClipAtPosition(position);

		const size = Timeline.getSize();
		const offset = Timeline.getOffset();

		if (position < offset || offset + size < position) {
			moveViewToPosition();
		}
	} else if (!isAction("record")) {
		if (isAction("start")) {
			stopRecorder();
		}

		startRecorder({ position });
	}
}
