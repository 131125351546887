import React, { memo, useCallback, useState } from "react";

import Rotate from "astrid-components/lib/components/Assets/Icons/Rotate";
import Button from "astrid-components/lib/components/Inputs/Button";
import Modal from "astrid-components/lib/components/Modules/Modal";
import Header from "astrid-components/lib/components/Text/Header";
import Text from "astrid-components/lib/components/Text/Text";

import { worker } from "../../helpers/firebase";
import { useRecorder } from "../../state/permissions";
import { getProductionId } from "../../state/productionId";

function Update() {
	const recorder = useRecorder();
	const [loading, setLoading] = useState();

	const onClick = useCallback(async () => {
		setLoading(true);
		await worker.call("recordings-batch", { productionId: getProductionId() });
		Modal.setContent();
	}, []);

	return (
		<>
			<Header textSize="large">Ny version av studion</Header>
			<Text>Produktionen behöver uppdateras för att fungera i den nya versionen.</Text>
			<Text>Efter uppdateringen går det ej att backa till en äldre version.</Text>
			<div style={{ height: 30 }} />
			{recorder &&
				(loading ? (
					<Rotate size={30} animation="rotate" />
				) : (
					<Button color="primary" onClick={onClick}>
						Uppdatera
					</Button>
				))}
		</>
	);
}

export default memo(Update);
