import React, { memo } from "react";

import Timeline from "astrid-components/lib/components/Audio/Timeline";

import useRecordingSilences from "../../hooks/useRecordingSilences";

const max = 5000;

function Silences({ clip, recording }) {
	const silences = useRecordingSilences(recording);

	if (!silences) return false;

	return silences.silences
		.filter((s) => clip.start < s[1] && s[0] < clip.end)
		.map((s) => (
			<Timeline.Selection
				key={s[0]}
				start={s[0]}
				end={s[1]}
				error={Math.min(s[1], clip.end) - Math.max(s[0], clip.start) > max}
			/>
		));
}

export default memo(Silences);
