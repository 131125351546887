import { clips } from "../state/clips";
import { getDuration } from "../state/duration";

import Document from "./document";

export default class Ad extends Document {
	get page() {
		return this.data.page || 1;
	}

	get text() {
		return this.data.text;
	}

	get staged() {
		return this.data.staged;
	}

	get checked() {
		return this.data.checked;
	}

	get swipe() {
		return this.staged ? 4 : 0;
	}

	get clipId() {
		return this.data.clipId || this.data.recordingId;
	}

	get clip() {
		return clips.get(this.clipId);
	}

	get position() {
		return this.clip ? this.clip.position : this.id === "pre" ? 2000 : getDuration() + 4000;
	}

	stage(staged) {
		staged = typeof staged !== "undefined" ? staged : !this.staged;
		const checked = this.checked && staged ? false : this.checked || false;

		this.update({ staged, checked });

		return staged ? 4 : 0;
	}

	check(checked) {
		checked = typeof checked !== "undefined" ? checked : !this.checked;
		const staged = this.staged && checked ? false : this.staged || false;

		this.update({ staged, checked });

		return checked ? -4 : 0;
	}
}
