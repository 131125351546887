import React, { memo } from "react";

import PauseIcon from "astrid-components/lib/components/Assets/Icons/Pause";
import PlayIcon from "astrid-components/lib/components/Assets/Icons/Play";
import Timeline from "astrid-components/lib/components/Audio/Timeline";

import useIsRole from "../../hooks/useIsRole";
import { toggleRecorder } from "../../services/recorder";
import { useActionType } from "../../state/action";
import { useProduction } from "../../state/production";

import Button from "./Button";

function Play() {
	const action = useActionType();
	const proofer = useIsRole("proofer");
	const position = Timeline.usePosition();
	const { id, render } = useProduction({});

	const modified = render?.modified;
	const disabled = !id || (proofer && modified && modified <= position);

	return (
		<Button color="positive" disabled={disabled} active={action !== "stop"} onClick={toggleRecorder}>
			{action === "stop" ? <PlayIcon /> : <PauseIcon />}
		</Button>
	);
}

export default memo(Play);
