import Transactions from "../classes/transactions";

import * as firebase from "../helpers/firebase";

import { onProductionId } from "./productionId";

export const transactions = new Transactions();

firebase.events.on("transactions", (snapshot) => {
	transactions.snapshot = snapshot;
});

onProductionId((id) => {
	if (id) {
		// worker.transactions(id);

		return () => transactions.clear();
	}
});
