import React, { memo, useCallback, useRef } from "react";
import { useDebouncedCallback } from "use-debounce";

import Pdf from "astrid-components/lib/components/Assets/Pdf";
import List from "astrid-components/lib/components/Data/List";
import Input from "astrid-components/lib/components/Inputs/Input";
import Flex from "astrid-components/lib/components/Layout/Flex";
import Text from "astrid-components/lib/components/Text/Text";
import { push } from "astrid-hooks/src/useHistory";

import * as firebase from "../../helpers/firebase";
import stopPropagation from "../../helpers/stopPropagation";
import useText from "../../hooks/useText";
import { jumpToPosition } from "../../services/timeline";
import { isAction } from "../../state/action";
import { useRecorder } from "../../state/permissions";
import { setSelectedPause, useSelectedPause } from "../../state/selectedPause";

import Content from "./Common/Content";
import PageButton from "./Common/PageButton";

function Chapter({ text, page, color, clip, length, position, ...props }) {
	const t = useText();
	const ref = useRef();
	const recorder = useRecorder();
	const selected = useSelectedPause() === position;

	const onClick = useCallback(() => {
		setSelectedPause(!selected && position);

		if (!isAction("record")) {
			jumpToPosition(position + length - 100);
			Pdf.setPage(page);
		}
	}, [length, page, position, selected]);

	const onChange = useDebouncedCallback((chapter) => {
		push(t("editChapterTitle", "Edit chapter title"), firebase.commit([clip.update({ chapter })]));
	}, 500);

	return (
		<List.Item ref={ref} color={color} active={selected} onClick={onClick} {...props}>
			<Flex cursor="pointer" minHeight={60} padding={10}>
				<PageButton transparent size="tiny" textSize="small" color={color}>
					{page}
				</PageButton>
				<Content color={color} label={t("chapter", "Chapter")} style={{ marginLeft: 10 }}>
					<Text fontWeight={600}>{text}</Text>
				</Content>
				<Flex marginLeft="auto" />
			</Flex>
			{recorder && selected && (
				<div style={{ padding: "0 20px 20px 62px" }} onClick={stopPropagation}>
					<Input
						size="small"
						placeholder={t("chapter", "Chapter")}
						defaultValue={text}
						style={{ flex: 1, height: "auto", lineHeight: "16px", padding: "8px 10px" }}
						onChange={({ target }) => onChange(target.value)}
					/>
				</div>
			)}
		</List.Item>
	);
}

export default memo(Chapter);
