import { getProductionId } from "../state/productionId";

import Part from "./part";
import Collection from "./collection";

const doneStatuses = ["completed", "updated", "error"];

export default class Parts extends Collection {
	get path() {
		return `productions/${getProductionId()}/parts`;
	}

	get last() {
		return this.sorted[this.sorted.length - 1];
	}

	get duration() {
		const last = this.last;

		return last ? last.stop : 0;
	}

	get completed() {
		return this.length > 0 && !this.find((part) => !doneStatuses.includes(part.status));
	}

	set(id, data) {
		if (data.status) {
			return super.set(id, new Part(id, data));
		}
	}

	sorter(a, b) {
		return a.start - b.start;
	}

	toLeft(position) {
		return this.map((part) => {
			part.dist = position - part.stop;
			return part;
		})
			.filter(({ dist }) => dist > 0)
			.sort((a, b) => a.dist - b.dist)[0];
	}

	atPosition(position) {
		return this.find((part) => part.isInside(position));
	}

	between(start, end) {
		return this.filter((part) => part.stop >= start && part.start <= end);
	}

	scope({ start, length }) {
		return this.between(start, start + length);
	}
}
