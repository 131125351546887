import { useMemo } from "react";

import Timeline from "astrid-components/lib/components/Audio/Timeline";

import { useSpaces } from "../state/spaces";

export default function useScopeSpaces() {
	const { start, length } = Timeline.useScope();
	const spaces = useSpaces();
	const end = start + length;

	return useMemo(
		() => spaces.filter(({ position, length }) => position + length > start && position < end),
		[end, spaces, start],
	);
}
