import React, { useCallback, useMemo } from "react";

import Timeline from "astrid-components/lib/components/Audio/Timeline";
import MouseLabel from "astrid-components/lib/components/Modules/MouseLabel";
import { push } from "astrid-hooks/src/useHistory";
import useMouseTouch from "astrid-hooks/src/useMouseTouch";

import cutClips from "../../helpers/cutClips";
import * as firebase from "../../helpers/firebase";
import useClosestSilence from "../../hooks/useClosestSilence";
import useText from "../../hooks/useText";
import { setTool } from "../../state/tool";

const times = [3000, 5000];

export default function PauseTool({ x, y }) {
	const t = useText();
	const range = useClosestSilence(x);

	const space = useMemo(() => (range && range[1] ? range[1] - range[0] : 0), [range]);

	const length = useMemo(() => {
		if (!y || !space) return 0;

		return times[Math.round(y)];
	}, [space, y]);

	const text = useMemo(
		() => (length === 5000 && t("chapterName", "Chapter")) || (length === 3000 && t("section", "Section")),
		[length, t],
	);

	const color = length === 5000 ? "positive" : length === 3000 ? "primary" : "default";

	const disabled = y < 0 || y > 1 || space <= 0 || space > 10000;

	const onMouseUp = useCallback(() => {
		setTool();
		MouseLabel.setLabel();

		if (!disabled) {
			const dist = length - space;

			push(
				"Lägg till paus",
				firebase.commit(
					cutClips({
						range,
						extend: dist,
						fadein: 50,
						fadeout: 100,
						select: true,
						overflow: true,
						transaction: true,
					}),
				),
			);
		}
	}, [range, disabled, length, space]);

	useMouseTouch("up", onMouseUp, window, true);

	if (disabled) return false;

	return (
		<>
			<Timeline.Range color={color} length={length} position={range[0]} />
			<Timeline.Range color={color} length={space} position={range[0]}>
				{text}
			</Timeline.Range>
		</>
	);
}
