import createStore from "astrid-helpers/src/createStore";

export const [getRedo, setRedo, useRedo] = createStore();
export const [getHistory, setHistory, useHistory] = createStore([]);

export const push = (name, fn, enabled) => {
	setRedo();
	setHistory((history) => [[name, fn, enabled || (() => true)], ...history]);
};

export function undo(action) {
	const history = getHistory();
	const [item, ...rest] = history;

	if (item[2](action)) {
		const redo = item[1]?.();

		setHistory(rest);
		setRedo(
			() =>
				redo &&
				(() => {
					setRedo();
					redo();
					push(...item);
				}),
		);

		return redo;
	}
}

export function redo() {
	return getRedo()?.();
}

export default useHistory;
