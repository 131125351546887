import Markers from "../classes/markers";

import { events, worker } from "../helpers/firebase";

import { onProductionId } from "./productionId";

export const markers = new Markers();

events.on("markers", (snapshot) => (markers.snapshot = snapshot));

onProductionId((id) => {
	if (id) {
		worker.markers(id);

		return () => markers.clear();
	}
});
