import Words from "../classes/words";

import { events, worker } from "../helpers/firebase";

import { onProductionId } from "./productionId";

export const words = new Words();

events.on("words", (snapshot) => {
	words.snapshot = snapshot;
});

onProductionId((id) => {
	if (id) {
		worker.words(id);

		return () => words.clear();
	}
});
