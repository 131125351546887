import React, { memo } from "react";

import Music from "astrid-components/lib/components/Assets/Icons/Music";

import { setTrack, useTrack } from "../../state/track";

import Button from "./Button";

function onClick() {
	setTrack((track) => (track === 1 ? 2 : 1));
}

function Tracks() {
	const track = useTrack();

	return (
		<Button label={track === 1 ? "Visa musikspår" : "Visa röstspår"} active={track === 2} onClick={onClick}>
			<Music />
		</Button>
	);
}

export default memo(Tracks);
