import { getUid } from "../state/user";

import Document from "./document";

export default class Production extends Document {
	get path() {
		return `productions/${this.id}`;
	}

	get title() {
		return this.data.title;
	}

	get studio() {
		return this.data.studio || {};
	}

	get version() {
		return this.studio.version || 1;
	}

	get proofer() {
		return this.data.proofer || [];
	}

	get editor() {
		return this.data.editor || [];
	}

	get manager() {
		return this.data.manager || [];
	}

	get reader() {
		return this.data.reader || [];
	}

	get producer() {
		return this.data.producer;
	}

	get language() {
		return this.data.language;
	}

	get languageOriginal() {
		return this.data.languageOriginal;
	}

	get render() {
		return this.data.render || {};
	}

	get master() {
		return this.data.master || {};
	}

	get author() {
		return this.data.author || [];
	}

	get progress() {
		return this.data.progress || {};
	}

	get compression() {
		return this.data.compression;
	}

	get reaperTemplates() {
		return this.data.reaperTemplates || {};
	}

	get rendered() {
		return this.render.time || this.progress.rendered || 0;
	}

	get modified() {
		return this.render.modified < this.rendered;
	}

	getProgressTime(type) {
		if (type === "proofed" && this.progress.proofed && !this.progress.proofed.amount) {
			return Object.values(this.progress.proofed)[0] || 0;
		}

		return typeof this.progress?.[type]?.time !== "undefined"
			? this.progress[type].time
			: this.progress?.[type] || 0;
	}

	getProgressAmount(type) {
		return this.progress?.[type]?.amount?.[getUid()] || this.progress?.[type]?.[getUid()] || 0;
	}

	updateProgress(type, progress, individual = false) {
		const uid = getUid();
		const time = this.getProgressTime(type);
		const amount = this.getProgressAmount(type);

		if (individual) {
			if (amount < progress) {
				return this.update({
					[`progress.${type}.amount.${uid}`]: progress,
					[`progress.${type}.time`]: time < progress ? progress : time,
				});
			}
		} else if (!time || time < progress) {
			return this.update({
				[`progress.${type}.amount.${uid}`]: amount + (progress - time),
				[`progress.${type}.time`]: progress,
			});
		}
	}

	restoreProgress(type, progress) {
		const time = this.getProgressTime(type);

		if (time > progress) {
			return this.update({
				[`progress.${type}.time`]: progress,
			});
		}
	}
}
