import Timeline from "astrid-components/lib/components/Audio/Timeline";

import { getPauses } from "../state/pauses";
import { getProductionId } from "../state/productionId";

import * as firebase from "./firebase";

export default function syncChapters(sync = true) {
	const duration = Timeline.getDuration() - Timeline.minDuration;

	return firebase.worker.setChapters(
		getProductionId(),
		sync &&
			getPauses()
				.filter(({ type }) => type === "chapter")
				.map(({ text, position, length }, i, m) => {
					const chapter = { text, position: position && position + length };
					const next = m[i + 1];

					chapter.length = next
						? next.position + next.length - chapter.position
						: duration + 2000 - chapter.position;

					return chapter;
				}),
	);
}
