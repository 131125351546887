import { getProductionId } from "../state/productionId";

import Collection from "./collection";
import Transaction from "./transaction";

export default class Transactions extends Collection {
	get path() {
		return `productions/${getProductionId()}/transactions`;
	}

	set(id, data) {
		return super.set(id, new Transaction(id, data, this));
	}

	sorter(a, b) {
		return b.created.seconds - a.created.seconds;
	}

	create(id, type, value = "") {
		return super.create(new Transaction(null, { id, type, value, created: true }, this));
	}
}
