import styled, { css } from "styled-components";

import Flex from "astrid-components/lib/components/Layout/Flex";
import MouseLabel from "astrid-components/lib/components/Modules/MouseLabel";
import backgrounds from "astrid-components/lib/theme/backgrounds";
import colors from "astrid-components/lib/theme/colors";

const userCircle = css`
	height: 44px;
	width: 44px;
	border-radius: 50%;
	border: 3px solid ${colors.default.light};
`;

const OuterDiv = styled(Flex)`
	justify-content: center;
	height: 50px;
	width: 50px;
	border-radius: 50%;

	${backgrounds.secondary.light}

	&:nth-child(n + 2) {
		margin-left: -16px;
	}
`;

const InnerImg = styled.img`
	${userCircle}
`;

const Letter = styled(Flex)`
	justify-content: center;
	${userCircle}
	background-color: rgba(141,185,226,0.24);
	color: ${colors.text.dark};
	font-size: 20px;
`;

const UserImg = ({ src, name }) => {
	return (
		<>
			<OuterDiv>
				<MouseLabel.Trigger label={name}>
					{src ? (
						<InnerImg src={src} />
					) : (
						<Letter>
							<span>{name.charAt(0)}</span>
						</Letter>
					)}
				</MouseLabel.Trigger>
			</OuterDiv>
		</>
	);
};

export default UserImg;
