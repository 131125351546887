import { getProductionId } from "../state/productionId";

import Ad from "./ad";
import Collection from "./collection";

export default class Ads extends Collection {
	get path() {
		return `productions/${getProductionId()}/ads`;
	}

	set(id, data) {
		return super.set(id, new Ad(id, data, this));
	}
}
