import createStore from "astrid-helpers/src/createStore";

import i18n from "../i18n";

import { clips } from "./clips";
import { getFlattenedClips, onFlattenedClips } from "./flattenedClips";
import { isChapter, isPause, setPauses } from "./pauses";

export const [getSpaces, setSpaces, useSpaces] = createStore([]);

onFlattenedClips(() => {
	const flattened = getFlattenedClips().filter((clip) => clip.track === 1);

	if (flattened.length > 1) {
		const spaces = [];
		const first = clips.get(flattened[0].id);

		const pauses = [
			{
				page: 1,
				length: 2000,
				position: 0,
				type: "chapter",
				color: "positive",
				clip: first,
				text: (first && first.chapter) || i18n.t("chapterAndNumber", "Chapter {{number}}", { number: 0 }),
			},
		];

		let chapter = 1;

		for (var i = 1; i < flattened.length; i++) {
			const prev = flattened[i - 1];
			const next = flattened[i];
			const end = next.position;
			const position = prev.position + prev.length;

			if (position < end) {
				const length = end - position;

				const type = (isChapter(length) && "chapter") || (isPause(length) && "paragraph") || "space";
				const text =
					(type === "chapter" && i18n.t("chapterAndNumber", "Chapter {{number}}", { number: chapter })) ||
					(type === "paragraph" && i18n.t("section", "Section")) ||
					`${Math.round(length / 10) / 100}s`;

				const color = type === "chapter" ? "positive" : type === "paragraph" ? "primary" : "default";

				const space = {
					type,
					text,
					color,
					length,
					position,
				};

				spaces.push(space);

				if (type === "chapter") {
					space.clip = clips.get(next.id);
					space.text = space.clip?.chapter || space.text;
					chapter += 1;
				}

				if (type !== "space") {
					pauses.push({
						...space,
						page: next.page,
					});
				}
			}
		}

		if (spaces.length > 0) {
			setSpaces(spaces);
		}

		setPauses(pauses);
	} else {
		if (getSpaces().length > 0) {
			setSpaces([]);
			setPauses([]);
		}
	}
});
