import { version } from "../../package.json";

import { getProductionId } from "../state/productionId";

import Recording from "./recording";
import Collection from "./collection";

export default class Recordings extends Collection {
	constructor() {
		super();

		this.maxNumber = 0;
	}

	get path() {
		return `productions/${getProductionId()}/recordings`;
	}

	get nextNumber() {
		this.maxNumber += 1;

		return this.maxNumber.toString().padStart(5, 0);
	}

	set(id, data) {
		const recording = new Recording(id, data, this);
		const number = parseInt(recording.id);

		if (number > this.maxNumber) {
			this.maxNumber = number;
		}

		return super.set(id, recording);
	}

	create(id, data = {}) {
		const recording = new Recording(
			null,
			{
				...data,
				number: id,
				version: 3,
				created: true,
				web: `${window.location.hostname} [${version}]`,
			},
			this,
		);

		recording.id = id;

		return super.create(recording);
	}

	clear() {
		super.clear();

		this.maxNumber = 0;
	}
}
