import Pdf from "astrid-components/lib/components/Assets/Pdf";
import markerTypes from "astrid-config/src/markerTypes";
import msToDateTime from "astrid-helpers/src/msToDateTime";

import { translations } from "../i18n";
import { parts } from "../state/parts";
import { timeline } from "../state/timeline";

import Document from "./document";

const expand = [0, 1];
const pauseTypes = ["paragraph", "chapter"];
const recordTypes = ["edit", "missing", "wrong", "noiseContent"];

export default class Marker extends Document {
	constructor(...args) {
		super(...args);

		this.search = this.text.toLowerCase();
		// this.highlight = (this.sentence || this.wrong || "").replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
		this.short = this.text.length > 30 ? `${this.text.substr(0, 28)}...` : this.text;
	}

	get key() {
		return super.key + this.position;
	}

	get position() {
		return this.time * 1000;
	}

	get part() {
		return this.data.part;
	}

	get page() {
		return this.data.page || 1;
	}

	get type() {
		return this.data.type;
	}

	get rects() {
		return this.data.rects;
	}

	get range() {
		return this.data.range;
	}

	get proof() {
		return this.data.proof;
	}

	get right() {
		return this.data.right;
	}

	get wrong() {
		return this.data.wrong;
	}

	get words() {
		return this.data.words;
	}

	get stale() {
		return this.data.stale;
	}

	get staged() {
		return this.data.staged;
	}

	get checked() {
		return this.data.checked;
	}

	get swipe() {
		return this.staged ? 4 : 0;
	}

	get created() {
		return this.data.created && this.data.created.seconds && msToDateTime(this.data.created.seconds * 1000);
	}

	get createdBy() {
		return this.data.createdBy;
	}

	get expand() {
		return this.data.expand || expand;
	}

	get sentence() {
		return this.data.sentence || this.data.scriptText;
	}

	get description() {
		return this.data.description || this.data.labelText;
	}

	get label() {
		return translations.markerTypes[this.type];
	}

	get labelEn() {
		return translations.markerTypesEn[this.type];
	}

	get color() {
		return markerTypes[this.type].color;
	}

	get fields() {
		return markerTypes[this.type].fields || [];
	}

	get showPause() {
		return pauseTypes.includes(this.type);
	}

	get showRecord() {
		return recordTypes.includes(this.type);
	}

	get showRange() {
		return this.showPause || this.showRecord;
	}

	get text() {
		return this.description || this.sentence || this.wrong || this.right || "";
	}

	get start() {
		const part = this.part && parts.get(this.part);

		return part ? part.start : 0;
	}

	get time() {
		const start = this.start;

		return start ? start / 1000 + this.data.time : timeline.markers[this.id];
	}

	canView(uid) {
		return !this.createdBy || this.createdBy.uid === uid;
	}

	includes(text) {
		return this.search.includes(text);
	}

	isBetween(start, end) {
		const position = this.position;

		return position > start && position < end;
	}

	isInsideClip(clip) {
		return this.isBetween(clip.position, clip.toOutside(clip.end));
	}

	stage(staged) {
		staged = typeof staged !== "undefined" ? staged : !this.staged;

		this.update({ staged });

		return staged ? 4 : 0;
	}

	check(checked) {
		checked = typeof checked !== "undefined" ? checked : !this.checked;

		this.update({ checked });

		return checked ? -4 : 0;
	}

	copy() {
		const selection = Pdf.getSelection();

		if (selection) {
			Pdf.setSelection();

			return this.update({
				rects: selection.rects,
				sentence: selection.text,
				page: selection.page || Pdf.getPage() || 1,
			});
		}
	}
}
