import { useEffect, useMemo, useRef, useState } from "react";

import List from "astrid-components/lib/components/Data/List";

import useRecordings from "../../hooks/useRecordings";
import { onSidebar } from "../../state/sidebar";

import AudioFile from "./AudioFile";
import Search from "./Search";

function AudioFileList() {
	const ref = useRef();
	const recordings = useRecordings();
	const [text, setText] = useState("");
	const [selected, setSelected] = useState();

	const search = useMemo(() => text?.toLowerCase(), [text]);

	const filtered = useMemo(
		() => recordings.filter((file) => !search || file.id.includes(search)),
		[search, recordings],
	);

	const toggleInfo = (index) => {
		if (selected !== index) {
			setSelected(index);
		} else {
			setSelected();
		}
	};

	const item = (index) => {
		if (index === 0) {
			return <Search text={text} setText={setText} />;
		} else {
			return (
				<AudioFile file={filtered[index - 1]} onClick={() => toggleInfo(index)} selected={selected === index} />
			);
		}
	};

	useEffect(
		() =>
			onSidebar((sidebar) => {
				if (sidebar === 2) {
					ref.current.scrollToIndex(0);
				}
			}),
		[],
	);

	return <List.Variable itemContent={item} totalCount={filtered.length + 1} ref={ref} />;
}

export default AudioFileList;
