import Clips from "../classes/clips";
import { flatten } from "../helpers/clips";
import * as firebase from "../helpers/firebase";

import { setFlattenedClips } from "./flattenedClips";
import { onProductionId } from "./productionId";
import { timeline } from "./timeline";

export const clips = new Clips();

clips.events.on("update", async () => {
	if (timeline.loaded) {
		setFlattenedClips(await flatten(clips.toObjectArray()));
	}
});

firebase.events.on("clips", (snapshot) => {
	clips.snapshot = snapshot;
});

onProductionId((id) => {
	if (id) {
		firebase.worker.clips(id);

		return () => clips.clear();
	}
});
