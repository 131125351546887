import decibelToVolume from "astrid-helpers/src/decibelToVolume";
import volumeToDecibel from "astrid-helpers/src/volumeToDecibel";

import { isAction } from "../state/action";
import { recordings } from "../state/recordings";
import { getSelectedClipId, setSelectedClipId } from "../state/selectedClipId";
import { timeline } from "../state/timeline";

import Document from "./document";

export default class Clip extends Document {
	get key() {
		return super.key + this.position;
	}

	get z() {
		return parseInt(this.number) || 0;
	}

	get page() {
		return this.pageOut;
	}

	get track() {
		return this.data.track || 1;
	}

	get pageIn() {
		return this.data.pageIn || this.data.page;
	}

	get pageOut() {
		return this.data.pageOut || this.data.page;
	}

	get start() {
		return this.data.start || 0;
	}

	get length() {
		return this.recording?.length;
	}

	get number() {
		return this.data.number;
	}

	get active() {
		return this.data.active;
	}

	get chapter() {
		return this.data.chapter;
	}

	get deleted() {
		return this.data.deleted;
	}

	get listened() {
		return this.data.listened;
	}

	get recording() {
		return recordings.get(this.number);
	}

	get silences() {
		return this.recording?.silences;
	}

	get end() {
		return this.data.end || this.length || 0;
	}

	get loading() {
		return !this.active && this.recording?.loading;
	}

	get volume() {
		return !isNaN(this.data.volume) ? this.data.volume : 1;
	}

	get position() {
		return timeline.clips[this.id] || this.data.position || 0;
	}

	get fadein() {
		return Math.min(!isNaN(this.data.fadein) ? this.data.fadein : 25, (this.end - this.start) * 0.8);
	}

	get fadeout() {
		return Math.min(!isNaN(this.data.fadeout) ? this.data.fadeout : 100, (this.end - this.start) * 0.8);
	}

	clone(data) {
		return this.collection.create(this.number, { ...this.data, ...data });
	}

	update({ position, ...data }) {
		if (position !== undefined) {
			this.deps = [timeline.set("clips", this.id, position)];
		}

		return super.update(data);
	}

	remove() {
		if (this.id === getSelectedClipId()) setSelectedClipId();

		return super.remove();
	}

	rollback() {
		const rollback = super.rollback();

		return () => {
			const data = rollback();

			if (data.active) {
				const precording = this?.recording?.precording;

				if (precording) precording.id = this.id;
				if (!isAction("record")) data.active = false;
			}

			return data;
		};
	}

	load() {
		return this.recording?.load();
	}

	isInside(value) {
		return this.position <= value && value <= this.toOutside(this.end);
	}

	toInside(value) {
		return value - this.position + this.start;
	}

	toOutside(value, from) {
		return (from || this.position) - this.start + value;
	}

	toObject() {
		let { volume } = this;
		const { z, id, end, page, track, start, number, fadein, fadeout, position, recording, listened } = this;
		const { url, source, length } = recording;

		// turn down the clip volume by -4db on the music track
		if (track === 2) {
			volume = decibelToVolume(volumeToDecibel(volume) - 4);
		}

		return {
			z,
			id,
			url,
			end,
			page,
			track,
			start,
			number,
			source,
			volume,
			length,
			fadein,
			fadeout,
			position,
			listened,
		};
	}

	findSilence(position) {
		return this.recording?.findSilence(this.toInside(position));
	}

	findClosestSilence(position) {
		return this.recording?.findClosestSilence(this.toInside(position));
	}

	async findAlignTo() {
		const silence = await this.findSilence(this.position);

		return silence ? this.toOutside(silence[1]) : this.position;
	}

	async findAlignToEnd(position = 0) {
		const silence = await this.findSilence(position);

		if (silence && silence[1] < this.end) {
			return Math.floor(this.toOutside(silence[1]));
		}
	}

	async trimStart(fadein = 100) {
		const silence = await this.findClosestSilence(this.position);

		if (silence) {
			fadein = Math.min(silence[1] - silence[0], fadein);

			const start = Math.floor(Math.max(0, silence[1] - fadein));
			const position = this.toOutside(start);

			return this.update({ position, start, fadein });
		}
	}

	async trimEnd(position = 0) {
		const silence = await this.findSilence(position);

		if (silence && silence[1] < this.end) {
			const end = Math.floor(Math.min(silence.lowest, silence[1] - 101));
			const fadeout = Math.floor(Math.max(100, end - silence[0]));

			return this.update({ end: end + fadeout, fadeout });
		}
	}
}
