import { clips } from "../state/clips";

import nudgeTimeline from "./nudgeTimeline";

export default async function alignEdit(edit, [start, end] = []) {
	const updates = [];

	if (edit && start && end) {
		const prev = clips.atPosition(start - 2, edit.id);
		const next = clips.atPosition(end - 2, edit.id);

		if (prev && next) {
			const [prev1, next1, edit1, edit2] = await Promise.all([
				prev.findSilence(start + 2),
				next.findSilence(end + 2),
				new Promise(async (r) => r((await edit.silences).silences.reverse().find((s) => s[0] < edit.start))),
				edit.findSilence(edit.toOutside(edit.end) - 10),
			]);

			let editPosition;
			let nextPosition;

			if (prev1 && prev1[1] < prev.end) {
				const end = prev1.lowest;

				updates.push(
					prev.update({
						end,
						fadeout: Math.max(100, end - prev1[0]),
					}),
				);

				editPosition = prev.toOutside(prev1[1]);
			}

			if (!editPosition) {
				const clip = clips.toRight(start - 3, [edit.id]);

				if (clip) editPosition = clip.position;
			}

			if (!editPosition) editPosition = edit.position;

			const hasSilences = edit2 && (!edit1 || edit1[0] !== edit2[0]);
			const editStart = edit1 ? edit1[1] : edit.start;
			const editFadeStart = hasSilences ? edit2[0] : edit.end;

			const prevLength = end - editPosition;
			const editLength = editFadeStart - editStart;
			const diff = editLength - prevLength;

			if (next1) {
				if (next1[1] < next.end) {
					nextPosition = next.toOutside(next1[1]) + diff;

					const nextData = {
						fadein: 25,
						start: next1[1],
						listened: false,
						position: nextPosition,
					};

					updates.push(prev === next ? next.clone(nextData) : next.update(nextData));
				} else if (prev !== next) {
					updates.push(next.update({ deleted: true }));
				}
			}

			if (!nextPosition) {
				const next = clips.toRight(end);

				if (next) nextPosition = next.position + diff;
			}

			if (!nextPosition) nextPosition = Infinity;

			// Delete clips under
			updates.push(
				...clips
					.filter((clip) => clip.id !== edit.id && start <= clip.position && clip.toOutside(clip.end) <= end)
					.map((clip) => clip.update({ deleted: true })),
			);

			// Move clips after
			updates.push(nudgeTimeline(end + 1, diff));

			const maxEditFadeEnd = edit.toInside(nextPosition) + (edit.toOutside(editStart) - editPosition);
			const editFadeEnd = Math.min(hasSilences ? edit2.lowest : edit.end, maxEditFadeEnd);

			// Trim new recording
			updates.push(
				edit.update({
					listened: false,
					start: editStart,
					end: editFadeEnd,
					position: editPosition,
					fadeout: Math.max(100, editFadeEnd - editFadeStart),
				}),
			);
		}
	}

	return updates;
}
