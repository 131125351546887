import React, { memo } from "react";

import Marker from "./Marker";

const types = [[], ["wrongPause"]];

function PauseMarker({ x, ...props }) {
	return <Marker proof x={x} types={types} {...props} />;
}

export default memo(PauseMarker);
