import React, { memo } from "react";

import Marker from "./Marker";

const types = [["noiseContent"], ["noisePause"]];

function NoiseMarker(props) {
	return <Marker proof types={types} {...props} />;
}

export default memo(NoiseMarker);
