import { useMemo } from "react";

import { useProductions } from "../state/productions";

export default function useProductionsOptions() {
	const productions = useProductions();

	return useMemo(
		() =>
			productions.map(({ id, data: { title } }) => ({
				value: id,
				label: title,
			})),
		[productions],
	);
}
