import React, { useCallback } from "react";

import Timeline from "astrid-components/lib/components/Audio/Timeline";
import clamp from "astrid-helpers/src/clamp";
import lerp from "astrid-helpers/src/lerp";
import useAsync from "astrid-hooks/src/useAsync";
import useMouseTouch from "astrid-hooks/src/useMouseTouch";

import enableAutoScroll from "../../helpers/enableAutoScroll";
import { startRecorder } from "../../services/recorder";
import { clips } from "../../state/clips";
import { setTool } from "../../state/tool";

const min = 1000;
const max = 8000;
const snap = 1000;

async function getPosition(x) {
	const clip = clips.atPosition(x);

	if (clip) {
		const find = clip.toInside(x);
		const s = await clip.findClosestSilence(x);

		if (s && s[0] && s[0] > clip.start && s[0] < clip.end && find + snap > s[0] && find - snap < s[1]) {
			return clip.toOutside(s[0]);
		}
	} else {
		const clip = clips.toLeft(x);

		if (clip) {
			if (clip.toOutside(clip.end) > x - 3000) {
				const s = await clip.findClosestSilence(x);

				if (s && s[0] < clip.end && clip.end < s[1]) {
					return clip.toOutside(s[0]);
				}

				return clip.toOutside(clip.end);
			}
		}
	}

	return 2000;
}

export default function Record({ x = 0, y = 0 } = {}) {
	const length = clamp(lerp(min, max, y), min, max);

	const position = useAsync(
		useCallback(() => getPosition(x), [x]),
		0,
	);

	const onMouseUp = useCallback(() => {
		setTool();

		if (position > 0) {
			startRecorder({ position: position - length, recordPosition: position });
			enableAutoScroll();
		}
	}, [length, position]);

	useMouseTouch("up", onMouseUp, window, true);

	return <Timeline.Range position={position - length} length={length} />;
}
