import React, { memo } from "react";

import Timeline from "astrid-components/lib/components/Audio/Timeline";

function Space({ text, color, length, position }) {
	return (
		<Timeline.Range color={color} length={length} position={position} hide={color === "default"}>
			{text}
		</Timeline.Range>
	);
}

export default memo(Space);
