import { toggleRecorder } from "../services/recorder";

document.addEventListener("keydown", (e) => {
	const tagName = e.target?.tagName?.toLowerCase();

	// Ignore key events from input fields
	if (tagName === "input" || tagName === "textarea") {
		return;
	}

	switch (e.code) {
		// Toggle play/pause with space key
		case "Space":
			toggleRecorder();
			break;

		default:
	}
});
