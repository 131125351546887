import React from "react";

import Timeline from "astrid-components/lib/components/Audio/Timeline";

import usePrerolling from "../../hooks/usePrerolling";
import { useAction } from "../../state/action";

export default function Preroll() {
	const prerolling = usePrerolling();
	const { position, recordPosition } = useAction();

	if (!prerolling) return false;

	return (
		<>
			<Timeline.Cursor color="primary" position={position} label="Pre-roll" />
			<Timeline.Cursor color="primary" position={recordPosition} />
		</>
	);
}
