import React, { memo } from "react";

import Label from "astrid-components/lib/components/Inputs/Label";
import Switch from "astrid-components/lib/components/Inputs/Switch";

import { setAutomation, useAutomation } from "../../state/automation";

function onClick() {
	setAutomation((automation) => !automation);
}

function Automation() {
	const automation = useAutomation();

	return (
		<Label text="Automation">
			<Switch value={automation} onClick={onClick} />
		</Label>
	);
}

export default memo(Automation);
