import { useCallback, useEffect, useState } from "react";

import createStore from "astrid-helpers/src/createStore";
import eventTarget from "astrid-helpers/src/eventTarget";
import { useStream } from "astrid-helpers/src/mediaDevices";
import createPeer from "astrid-helpers/src/peer";

import * as firebase from "../helpers/firebase";
import { useAdmin } from "../state/permissions";
import { useProfile, useUid } from "../state/user";

const dummy = new Audio();

export const messages = eventTarget();

export const [getConnected, setConnected, useConnected, onConnected] = createStore(false);
export const [getCurrentPeer, setCurrentPeer, useCurrentPeer, onCurrentPeer] = createStore(null);
export const [getIncomingStream, setIncomingStream, useIncomingStream, onIncomingStream] = createStore(null);

export function sendMessage(...args) {
	const peer = getCurrentPeer();
	const connected = getConnected();

	if (peer && connected) {
		peer.sendMessage(...args);
	}
}

export default function usePeer({ studio, online }) {
	const uid = useUid();
	const user = useProfile();
	const admin = useAdmin();
	const stream = useStream();
	const connected = useConnected();
	const currentPeer = useCurrentPeer();
	const incomingStream = useIncomingStream();

	const [connecting, setConnecting] = useState(false);

	const occupied = studio?.status === "occupied";

	const canConnect = !!studio && online && !!stream && !currentPeer && !occupied && !connected && !connecting;
	const canDisconnect = !!studio && (connected || connecting || (occupied && admin));
	const canHangup = canDisconnect && !currentPeer && studio?.data.uid === uid;

	// Fix for bug https://bugs.chromium.org/p/chromium/issues/detail?id=933677
	useEffect(() => {
		dummy.srcObject = incomingStream;
	}, [incomingStream]);

	const connect = useCallback(() => {
		if (stream) {
			const peer = createPeer({
				stream,
				initiator: true,
			});

			setCurrentPeer(peer);
		}
	}, [stream]);

	const disconnect = useCallback(() => {
		if (canHangup) {
			firebase.worker.update(`studios/${studio.id}`, {
				uid: null,
				user: null,
				hangup: true,
			});
		}

		setCurrentPeer(null);
	}, [canHangup, studio]);

	// Disconnect on offline
	useEffect(() => {
		if (!online) {
			disconnect();
		}
	}, [online, disconnect]);

	// Peer events
	useEffect(() => {
		if (currentPeer) {
			firebase.worker.update(`studios/${studio.id}`, {
				uid,
				user: user?.email,
			});

			firebase.worker.signals(studio.id);
			firebase.events.on("signal", (signal) => currentPeer.sendSignal(signal));

			setConnecting(true);

			currentPeer.on("stream", (incomingStream) => {
				if (currentPeer === getCurrentPeer()) {
					setIncomingStream(incomingStream);
				}
			});

			currentPeer.on("signal", (signal) => {
				if (currentPeer === getCurrentPeer()) {
					firebase.worker.signal(studio.id, signal);
				}
			});

			currentPeer.on("connect", () => {
				if (currentPeer === getCurrentPeer()) {
					setConnected(true);
					setConnecting(false);
				}
			});

			currentPeer.on("destroy", () => {
				if (currentPeer === getCurrentPeer()) {
					disconnect();
				}
			});

			currentPeer.messages.addRelay(messages);

			return () => {
				firebase.worker.signals();
				firebase.events.off("signal");

				setConnected(false);
				setConnecting(false);
				setIncomingStream(null);

				currentPeer.destroy();
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPeer]);

	return {
		peer: currentPeer,
		incomingStream,

		connect,
		disconnect,

		connected,
		connecting,

		canConnect,
		canDisconnect,

		sendMessage,
	};
}
