import React, { memo } from "react";

import Pin from "astrid-components/lib/components/Assets/Icons/Pin";
import Timeline from "astrid-components/lib/components/Audio/Timeline";

import enableAutoScroll from "../../helpers/enableAutoScroll";
import { moveViewToPosition } from "../../services/timeline";
import { useAutoScroll } from "../../state/autoScroll";

import Button from "./Button";

function onClick() {
	Timeline.resetSize();
	enableAutoScroll();
	moveViewToPosition();
}

function Mode() {
	const autoScroll = useAutoScroll();

	return (
		<Button active={!autoScroll} onClick={onClick}>
			<Pin animation={!autoScroll && "blink"} />
		</Button>
	);
}

export default memo(Mode);
