import React, { memo } from "react";

import Timeline from "astrid-components/lib/components/Audio/Timeline";
import Input from "astrid-components/lib/components/Inputs/Input";
import Flex from "astrid-components/lib/components/Layout/Flex";
import Header from "astrid-components/lib/components/Text/Header";
import msToTime from "astrid-helpers/src/msToTime";

import { moveViewToPosition, updatePosition } from "../../services/timeline";

function onChange(value) {
	updatePosition(value, false);
	moveViewToPosition();
}

function CurrentTime() {
	const position = Timeline.usePosition();

	return (
		<Input.Time
			size="small"
			textSize="large"
			value={position}
			style={{ width: 100, textAlign: "center" }}
			onChange={onChange}
		/>
	);
}

function Duration() {
	const duration = Timeline.useDuration();

	return (
		<Header color="default" style={{ marginLeft: 10, fontSize: 16, fontWeight: 300 }}>
			{" / "}
			{msToTime(duration, false)}
		</Header>
	);
}

export default memo(() => (
	<Flex paddingRight={10}>
		<CurrentTime />
		<Duration />
	</Flex>
));
