import Pdf from "astrid-components/lib/components/Assets/Pdf";
import markerTypes from "astrid-config/src/markerTypes";

import { getRole } from "../state/permissions";
import { getProductionId } from "../state/productionId";
import { timeline } from "../state/timeline";

import Collection from "./collection";
import Marker from "./marker";

export default class Markers extends Collection {
	get path() {
		return `productions/${getProductionId()}/markers`;
	}

	set(id, data) {
		if (markerTypes[data.type]) {
			return super.set(id, new Marker(id, data, this));
		}
	}

	sorter(a, b) {
		return a.position - b.position;
	}

	between(start, end) {
		return this.filter((marker) => marker.isBetween(start, end));
	}

	scope({ start, length }) {
		return this.between(start, start + length).filter(({ checked }) => !checked);
	}

	atClip(clip) {
		return this.between(clip.position, clip.toOutside(clip.end));
	}

	withWords() {
		return this.filter(({ words }) => words);
	}

	create(type, position) {
		if (!markerTypes[type]) return;

		const selection = Pdf.getSelection();
		const fields = markerTypes[type].fields;

		let data = {
			type,
			created: true,
			page: Pdf.getPage() || 1,
			proof: getRole() === "proofer",
		};

		if (selection) {
			const copyToWrong = fields && fields.find(({ key }) => key === "wrong");
			const copyToSentence = !copyToWrong && fields && fields.find(({ key }) => key === "sentence");
			const copy = copyToWrong || copyToSentence;

			data = {
				...data,
				page: copy ? selection.page : data.page,
				rects: copyToSentence ? selection.rects : false,
				wrong: (copyToWrong && selection && selection.text) || "",
				sentence: (copyToSentence && selection && selection.text) || "",
			};
		}

		Pdf.setSearch("");
		Pdf.setSelection();

		const marker = new Marker(null, data, this);

		marker.deps = [timeline.set("markers", marker.id, position / 1000)];

		return super.create(marker);
	}
}
