import React, { memo } from "react";
import useNetworkState from "react-use/lib/useNetworkState";

import BookmarkIcon from "astrid-components/lib/components/Assets/Icons/Bookmark";
import ClockIcon from "astrid-components/lib/components/Assets/Icons/Clock";
import EditIcon from "astrid-components/lib/components/Assets/Icons/Edit";
import MarkerIcon from "astrid-components/lib/components/Assets/Icons/Marker";
import MessageIcon from "astrid-components/lib/components/Assets/Icons/Message";
import ScissorsIcon from "astrid-components/lib/components/Assets/Icons/Scissors";
import TrashIcon from "astrid-components/lib/components/Assets/Icons/Trash";
import ZapIcon from "astrid-components/lib/components/Assets/Icons/Zap";
import Button from "astrid-components/lib/components/Inputs/Button";
import Divider from "astrid-components/lib/components/Layout/Divider";
import Flex from "astrid-components/lib/components/Layout/Flex";

import useCanRecord from "../../hooks/useCanRecord";
import { useEditor, useRecorder } from "../../state/permissions";
import { useShowRendered } from "../../state/showRendered";
import { useTrack } from "../../state/track";

import ContentMarker from "../Tools/ContentMarker";
import CutTool from "../Tools/Cut";
import EditTool from "../Tools/Edit";
import MemoMarker from "../Tools/MemoMarker";
import NoiseMarker from "../Tools/NoiseMarker";
import PauseTool from "../Tools/Pause";
import PauseMarker from "../Tools/PauseMarker";
import TimingTool from "../Tools/Timing";
import TimingMarker from "../Tools/TimingMarker";
import TrashTool from "../Tools/Trash";

import Amp from "./Amp";
import Listen from "./Listen";
import Mode from "./Mode";
import MuteSelf from "./MuteSelf";
import MuteStudio from "./MuteStudio";
import Next from "./Next";
import Play from "./Play";
import Prev from "./Prev";
import Proof from "./Proof";
import Record from "./Record";
import Time from "./Time";
import Tool from "./Tool";
import Tracks from "./Tracks";
import Undo from "./Undo";

function Toolbar() {
	const track = useTrack();
	const editor = useEditor();
	const recorder = useRecorder();
	const canRecord = useCanRecord();
	const showRendered = useShowRendered();

	const { online } = useNetworkState();

	return (
		<Flex>
			<Flex alignItems="stretch" minWidth={150}>
				{recorder && (
					<>
						<Flex padding={8}>
							<Tracks />
						</Flex>
						<Divider darker vertical />
						<Flex padding={8}>
							<MuteSelf />
							<MuteStudio />
							<Amp />
						</Flex>
					</>
				)}
			</Flex>
			<Flex alignItems="stretch">
				{recorder ? (
					<>
						<Flex padding={8}>
							<Mode />
						</Flex>
						<Divider darker vertical />
						<Flex padding={8}>
							<Play />
							<Record />
						</Flex>
						<Divider darker vertical />
						<Flex padding={8}>
							{/* <Tool tool={LassoTool}>
								<LassoIcon />
							</Tool> */}
							<Tool tool={PauseTool} disabled={track !== 1 || showRendered}>
								<BookmarkIcon />
							</Tool>
							<Tool tool={TimingTool} disabled={track !== 1 || showRendered}>
								<ClockIcon />
							</Tool>
							<Tool tool={EditTool} disabled={!canRecord || track !== 1 || showRendered}>
								<EditIcon />
							</Tool>
							<Tool tool={CutTool} disabled={showRendered}>
								<ScissorsIcon />
							</Tool>
							<Tool tool={TrashTool} disabled={showRendered}>
								<TrashIcon />
							</Tool>
						</Flex>
						<Divider darker vertical />
						<Flex padding={8}>
							{!canRecord && (
								<Tool tool={NoiseMarker} disabled={!online}>
									<ZapIcon />
								</Tool>
							)}
							<Tool tool={ContentMarker} disabled={!online}>
								<MessageIcon />
							</Tool>
							<Tool tool={MemoMarker} disabled={!online}>
								<MarkerIcon />
							</Tool>
						</Flex>
						<Divider darker vertical />
						<Flex padding={8}>
							<Undo />
						</Flex>
					</>
				) : (
					<>
						<Flex padding={8}>
							<Proof />
							<Play />
						</Flex>
						<Divider darker vertical />
						<Flex padding={8}>
							<Tool tool={PauseMarker}>
								<BookmarkIcon />
							</Tool>
							<Tool tool={NoiseMarker}>
								<ZapIcon />
							</Tool>
							<Tool tool={TimingMarker}>
								<ClockIcon />
							</Tool>
							<Tool tool={ContentMarker}>
								<MessageIcon />
							</Tool>
							<Tool tool={MemoMarker}>
								<MarkerIcon />
							</Tool>
						</Flex>
					</>
				)}
			</Flex>
			{editor && (
				<Flex padding={8}>
					<Button.Group>
						<Prev />
						<Next />
					</Button.Group>
					<div style={{ width: 10 }} />
					<Listen />
				</Flex>
			)}
			<Flex alignItems="stretch">
				<Flex padding={8}>
					<Time />
				</Flex>
			</Flex>
		</Flex>
	);
}

export default memo(Toolbar);
