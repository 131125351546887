import React, { memo, useCallback, useState } from "react";

import Label from "astrid-components/lib/components/Inputs/Label";
import Switch from "astrid-components/lib/components/Inputs/Switch";

import syncChapters from "../../helpers/syncChapters";
import useText from "../../hooks/useText";
import { useChapters } from "../../state/chapters";

function Chapers() {
	const t = useText();
	const chapters = useChapters();

	const [sync, setSync] = useState(!!chapters);

	const onClick = useCallback(() => {
		setSync(!sync);
		syncChapters(!sync);
	}, [sync]);

	return (
		<Label text={t("chapterName", "Chapter")}>
			<Switch value={chapters} onClick={onClick} />
		</Label>
	);
}

export default memo(Chapers);
