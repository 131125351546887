import React, { memo, useCallback, useRef } from "react";

import Pdf from "astrid-components/lib/components/Assets/Pdf";
import List from "astrid-components/lib/components/Data/List";

import { jumpToPosition } from "../../services/timeline";
import { isAction } from "../../state/action";

import ListItem from "./Common/ListItem";

function Pause({ text, page, color, length, position, ...props }) {
	const ref = useRef();

	const onClick = useCallback(() => {
		if (!isAction("record")) {
			jumpToPosition(position + length - 100);
			Pdf.setPage(page);
		}
	}, [length, page, position]);

	return (
		<List.Item ref={ref} color={color} {...props}>
			<ListItem text={text} page={page} color={color} position={position} onClick={onClick} />
		</List.Item>
	);
}

export default memo(Pause);
