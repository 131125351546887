import { useMemo } from "react";

import useSilences from "./useSilences";

export default function useSelectionRange(x, [start, length] = [0, 0]) {
	const silences = useSilences();

	const positions = useMemo(() => silences.map((s) => s[0]), [silences]);
	const closest = useMemo(() => positions.find((p, i, map) => map[i + 1] && p < x && x < map[i + 1]), [positions, x]);

	const index = useMemo(
		() => closest && Math.max(0, positions.indexOf(closest) + start),
		[closest, positions, start],
	);

	length = length - start;

	const range = useMemo(
		() =>
			length &&
			index !== undefined &&
			positions.length > 1 &&
			positions[index] && [positions[index], positions[index + length] || positions[positions.length - 1]],
		[length, index, positions],
	);

	return [range, silences, index];
}
