import React, { memo, useCallback, useState } from "react";
import useDebounce from "react-use/lib/useDebounce";

import PlusIcon from "astrid-components/lib/components/Assets/Icons/Plus";
import Pdf from "astrid-components/lib/components/Assets/Pdf";
import List from "astrid-components/lib/components/Data/List";
import Button from "astrid-components/lib/components/Inputs/Button";
import Input from "astrid-components/lib/components/Inputs/Input";
import Flex from "astrid-components/lib/components/Layout/Flex";

import useText from "../../hooks/useText";

function clear() {
	Pdf.setSearchIndex();
	Pdf.setSearchResults([]);
}

function Form(props) {
	const t = useText();
	const [search, setSearch] = useState(Pdf.useSearch());

	const onChange = useCallback((event) => {
		if (Pdf.getSearchResults().length > 0) {
			clear();
		}

		setSearch(event.target.value);
	}, []);

	const onClear = useCallback(() => {
		clear();
		setSearch("");
	}, []);

	useDebounce(
		() => {
			Pdf.setSearch(search);
		},
		200,
		[search],
	);

	return (
		<List.Item first {...props}>
			<Flex height={45} padding="0 10px">
				<Input
					transparent
					size="small"
					value={search}
					placeholder={t("search", "Search") + "..."}
					onChange={onChange}
				/>

				{search && (
					<Flex marginLeft="auto">
						<Button transparent size="small" onClick={onClear}>
							<PlusIcon size={16} transform="rotate(45)" />
						</Button>
					</Flex>
				)}
			</Flex>
		</List.Item>
	);
}

export default memo(Form);
