import * as Sentry from "@sentry/browser";

import { isProduction, isStage } from "astrid-helpers/src/env";

import { name, version } from "../package.json";

if (isProduction) {
	Sentry.init({
		release: `${name}@${version}`,
		dsn: "https://aa529a65126049eca09c125ec5d3dcb8@sentry.io/1792457",
		environment: (isProduction && ((isStage && "staging") || "production")) || "development",
		ignoreErrors: [
			"ResizeObserver loop limit exceeded",
			"ResizeObserver loop completed with undelivered notifications",
			/^Firebase Storage.*svg.*does not exist$/,
		],
	});
}

export default Sentry;
