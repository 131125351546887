import createStore from "astrid-helpers/src/createStore";

import { onFlattenedClips } from "./flattenedClips";

export const [getEdited, setEdited, useEdited, onEdited] = createStore(0);

onFlattenedClips((flattenedClips) => {
	const firstUnlistenedClipIndex = flattenedClips.findIndex((clip) => !clip.listened);
	const firstUnlistenedClip =
		flattenedClips[firstUnlistenedClipIndex === 0 ? firstUnlistenedClipIndex : firstUnlistenedClipIndex - 1];
	const lastClip = flattenedClips[flattenedClips.length - 1];

	if (firstUnlistenedClip) {
		setEdited(firstUnlistenedClip.position);
	} else if (lastClip) {
		setEdited(lastClip.position + lastClip.length);
	}
});
