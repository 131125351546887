import React, { useEffect } from "react";

import Timeline from "astrid-components/lib/components/Audio/Timeline";
import map from "astrid-helpers/src/map";
import useMouse from "astrid-hooks/src/useMouse";

import disableAutoScroll from "../../helpers/disableAutoScroll";
import enableAutoScroll from "../../helpers/enableAutoScroll";
import { getRecorder } from "../../state/permissions";
import { useTool } from "../../state/tool";

const Tool = ({ tool: Component }) => {
	const size = Timeline.useSize();
	const rect = Timeline.useRect();
	const offset = Timeline.useOffset();

	const { x, y } = useMouse(rect);

	useEffect(() => {
		if (getRecorder() && disableAutoScroll()) {
			return enableAutoScroll;
		}
	}, []);

	return <Component y={y / rect.height} x={offset + map(x, 0, rect.width, 0, size)} />;
};

export default () => {
	const tool = useTool();

	if (!tool) {
		return null;
	}

	return <Tool tool={tool} />;
};
