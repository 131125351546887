import React, { memo } from "react";

import Button from "astrid-components/lib/components/Inputs/Button";
import MouseLabel from "astrid-components/lib/components/Modules/MouseLabel";

function ToolButton({ label, ...props }) {
	return (
		<MouseLabel.Trigger label={label}>
			<Button transparent color="primary" {...props} />
		</MouseLabel.Trigger>
	);
}

export default memo(ToolButton);
