import { clips } from "../state/clips";
import { markers } from "../state/markers";
import { timeline } from "../state/timeline";

export default function nudgeTimeline(start = Infinity, dist, ignoreIds = []) {
	return timeline.update(
		markers
			.filter(({ position }) => position >= start)
			.reduce(
				(data, marker) => {
					data[`markers.${marker.id}`] = marker.time + dist / 1000;
					return data;
				},
				clips
					.filter((clip) => !ignoreIds.includes(clip.id) && clip.track === 1 && clip.position >= start)
					.reduce((data, clip) => {
						data[`clips.${clip.id}`] = clip.position + dist;
						return data;
					}, {}),
			),
	);
}
