import React, { useCallback, useMemo } from "react";

import Timeline from "astrid-components/lib/components/Audio/Timeline";
import MouseLabel from "astrid-components/lib/components/Modules/MouseLabel";
import map from "astrid-helpers/src/map";
import { push } from "astrid-hooks/src/useHistory";
import useMouseTouch from "astrid-hooks/src/useMouseTouch";

import cutClips from "../../helpers/cutClips";
import * as firebase from "../../helpers/firebase";
import useClosestSilence from "../../hooks/useClosestSilence";
import { setTool } from "../../state/tool";

const snap = 1800;
const max = 2000;

export default function TimingTool({ x, y }) {
	const range = useClosestSilence(x);

	const space = useMemo(() => (range && range[1] ? range[1] - range[0] : 0), [range]);

	const length = useMemo(() => {
		if (!y || !space) return 0;

		const length = Math.round(map(y, 0, 0.85, 0, max));

		return length < snap ? length : max;
	}, [space, y]);

	const text = useMemo(() => `${Math.round(length / 10) / 100}s`, [length]);

	const disabled = y < 0 || y > 1 || space <= 0 || space > 10000;

	const onMouseUp = useCallback(() => {
		setTool();
		MouseLabel.setLabel();

		if (!disabled) {
			push(
				"Lägg till paus",
				firebase.commit(
					cutClips({
						range,
						fadein: 50,
						fadeout: 100,
						select: true,
						overflow: true,
						transaction: true,
						extend: length - space,
					}),
				),
			);
		}
	}, [range, disabled, length, space]);

	useMouseTouch("up", onMouseUp, window, true);

	if (disabled) return false;

	return (
		<>
			<Timeline.Range length={length} position={range[0]} />
			<Timeline.Range length={space} position={range[0]}>
				{text}
			</Timeline.Range>
		</>
	);
}
