import createStore from "astrid-helpers/src/createStore";

import { events } from "../helpers/firebase";

export const [getUid, setUid, useUid, onUid] = createStore();
export const [getProfile, setProfile, useProfile, onProfile] = createStore();

events.on("user", ({ uid, profile }) => {
	setUid(uid);
	setProfile(profile);
});
