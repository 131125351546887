import React, { memo } from "react";

import Label from "astrid-components/lib/components/Inputs/Label";
import Range from "astrid-components/lib/components/Inputs/Range";

import { setGateOptions, useGateOptions } from "../../state/gateOptions";

function Gate() {
	const { min } = useGateOptions();

	return (
		<>
			<Label text="Min">
				<Range
					min={0}
					max={0.1}
					step={0.001}
					value={min}
					onChange={(min) => setGateOptions((options) => ({ ...options, min }))}
				/>
				<Range.Label>{min}</Range.Label>
			</Label>
		</>
	);
}

export default memo(Gate);
