import Pdf from "astrid-components/lib/components/Assets/Pdf/Pdf";

import { jumpToPosition } from "../services/timeline";
import { isAction } from "../state/action";
import { markers } from "../state/markers";
import { getSidebar, setSidebar } from "../state/sidebar";

import enableAutoScroll from "./enableAutoScroll";

export default function jumpToMarker(id) {
	if (id) {
		if (getSidebar() !== 0) {
			setSidebar(0);
		}

		const marker = markers.get(id);

		if (marker && marker.position && !isAction("record")) {
			jumpToPosition(marker.position);
			enableAutoScroll();

			if (marker.rects) {
				Pdf.setScrollOffset([
					marker.page - 1 + marker.rects.reduce((sum, { top }) => sum + top, 0) / marker.rects.length,
					true,
				]);
			} else if (Pdf.getPage() !== marker.page) {
				Pdf.setPage(marker.page);
			}
		}
	}
}
