import { useEffect } from "react";
import useNetworkState from "react-use/lib/useNetworkState";

import Sentry from "../sentry";
import { useUid, useProfile } from "../state/user";

export default function useSentry() {
	const uid = useUid();
	const profile = useProfile();
	const network = useNetworkState();

	useEffect(() => {
		if (profile) {
			Sentry.setUser({
				id: uid,
				email: profile.email,
				username: profile.firstName + " " + profile.lastName,
			});
		}
	}, [profile, uid]);

	useEffect(() => {
		if (network) {
			Sentry.setTag(
				"network",
				Object.entries(network)
					.filter(([, value]) => value)
					.map((entry) => entry.join(": "))
					.join(", "),
			);
		}
	}, [network]);

	return Sentry;
}
