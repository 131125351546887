import createStore from "astrid-helpers/src/createStore";

import * as firebase from "../helpers/firebase";

import { onProducers } from "./producers";

export const [getProductions, setProductions, useProductions, onProductions] = createStore([]);

onProducers((producers) => {
	const beta = window.location.hostname === "beta.studio.astrid.fm";

	firebase.worker.productions(producers, beta).then(setProductions);

	return () => setProductions([]);
});
