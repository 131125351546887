import cutClips from "../helpers/cutClips";

import { clips } from "../state/clips";
import { recordings } from "../state/recordings";

import Peaks from "./peaks";

export default class Precording {
	constructor(number, action) {
		this.length = 0;
		this.align = false;
		this.id = undefined;
		this.number = number;
		this.action = action;
		this.peaks = new Peaks(this);
		this.undo = () => {};
	}

	get startPosition() {
		return this.action.position;
	}

	get recordPosition() {
		return this.action.recordPosition || this.startPosition;
	}

	get recording() {
		return this.number && recordings.get(this.number);
	}

	get clip() {
		return this.id && clips.get(this.id);
	}

	get clips() {
		return clips.filter((clip) => clip.number === this.number);
	}

	append(peaks, length) {
		this.peaks.append(peaks);
		this.length += length;
	}

	remove() {
		this.deleted = true;
		this.peaks = new Peaks(this);

		return this.clips.map((clip) => clip.remove());
	}

	trim() {
		return Promise.all([this.alignClip && this.alignClip.trimEnd(this.recordPosition + 10), this.clip.trimStart()]);
	}

	cutAround() {
		const { clips } = this;
		const first = clips[0];
		const last = clips[clips.length - 1];

		if (first && last) {
			return cutClips({
				ignoreIds: clips.map((clip) => clip.id),
				range: [first.position, last.toOutside(last.end)],
			});
		}
	}

	async findAlignTo() {
		const findAt = this.recordPosition + 10;
		const clip = clips.atPosition(findAt);

		if (clip) {
			this.alignClip = clip;
			this.alignTo = await clip.findAlignToEnd(findAt);
		}

		if (!this.alignTo) {
			const right = clips.toRight(findAt);

			if (right) {
				this.alignTo = await right.findAlignTo();
			}
		}
	}
}
