import List from "astrid-components/lib/components/Data/List";
import Input from "astrid-components/lib/components/Inputs/Input";
import Flex from "astrid-components/lib/components/Layout/Flex";

import useText from "../../hooks/useText";

export default function Search({ text, setText }) {
	const t = useText();

	const onChangeText = (e) => {
		setText(e.target.value);
	};

	return (
		<List.Item first>
			<Flex height={45} padding="0 10px">
				<Input
					transparent
					size="small"
					value={text}
					placeholder={t("searchAudioFile", "Search audio file...")}
					onChange={onChangeText}
				/>
			</Flex>
		</List.Item>
	);
}
