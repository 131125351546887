import React, { memo } from "react";

import Label from "astrid-components/lib/components/Inputs/Label";

import useText from "../../hooks/useText";
import { useProduction } from "../../state/production";
import { useProductionLink } from "../../state/productionLink";

function Settings() {
	const t = useText();
	const production = useProduction();
	const productionLink = useProductionLink();

	return (
		<Label text={t("settings", "Settings")}>
			<a target="_blank" rel="noopener noreferrer" href={productionLink}>
				{production?.title}
			</a>
		</Label>
	);
}

export default memo(Settings);
