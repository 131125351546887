import Pdf from "astrid-components/lib/components/Assets/Pdf";

import { clips } from "../state/clips";
import { setSelectedClipId } from "../state/selectedClipId";

import * as master from "./master";
import nudgeTimeline from "./nudgeTimeline";

export default function cutClips({
	range,
	track = 1,
	extend = 0,
	fadein = 25,
	fadeout = 25,
	ignoreIds = [],
	select = false,
	overflow = false,
}) {
	const updates = [];
	const createClips = [];

	const [start, end] = range;
	const position = end + extend;
	const delta = overflow ? -fadein : 0;

	const filteredClips = clips.filter(
		(clip) =>
			clip.track === track &&
			!ignoreIds.includes(clip.id) &&
			clip.toOutside(clip.end) > start &&
			clip.position < end,
	);

	const getData = (clip) => ({
		fadein,
		listened: false,
		position: position + delta,
		start: Math.max(0, clip.toInside(end) + delta),
	});

	// Modify production
	const modify = master.modify(start);
	if (modify) {
		updates.push(modify);
	}

	// Move clips
	if (extend !== 0) {
		updates.push(nudgeTimeline(end, extend));
	}

	// Trim clips
	filteredClips.forEach((clip) => {
		if (start <= clip.position) {
			if (end >= clip.toOutside(clip.end)) {
				updates.push(clip.update({ deleted: true }));
			} else {
				const { precording } = clip.recording;

				if (precording) {
					precording.alignTo = position;
				}

				updates.push(clip.update(getData(clip)));
			}
		} else {
			updates.push(clip.update({ fadeout, active: false, end: clip.toInside(start) }));

			if (end < clip.toOutside(clip.end)) {
				createClips.push(clip);
			}
		}
	});

	// Create clips
	createClips.forEach((clip) => {
		const clone = clip.clone({
			...getData(clip),
			pageIn: (clip.active ? Pdf.getPage() : clip.pageIn) || 1,
		});

		updates.push(clone);

		if (select) {
			setSelectedClipId(clone.id);
		}

		if (clip.active) {
			const precording = clip?.recording?.precording;

			if (precording && precording.id) {
				precording.id = clone.id;
			}
		}
	});

	return updates;
}
