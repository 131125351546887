import Cache from "lru-cache";

import Silence from "../classes/silence";
import Silences from "../classes/silences";
import { getGateOptions } from "../state/gateOptions";
import { getProductionId } from "../state/productionId";

import { worker } from "./audio";
import * as peaks from "./peaks";

function dist(silence, position) {
	const dist1 = Math.abs(silence[0] - position);
	const dist2 = Math.abs(silence[1] - position);

	return dist1 < dist2 ? dist1 : dist2;
}

export const cache = new Cache({ max: 100 });

export function from(recording, options) {
	options = options || getGateOptions();

	const { id, url } = recording;
	const key = getProductionId() + id + url + JSON.stringify(options);

	if (cache.has(key)) {
		return cache.get(key);
	}

	return (async () => {
		const p = await peaks.from(recording);
		const max = p.peaks.map(([min, max]) => Math.max(min, max));
		const silences = new Silences((await worker.silences(max, options)).map((silence) => new Silence(p, silence)));

		if (recording.url) {
			cache.set(key, silences);
		}

		return silences;
	})();
}

export function closest(silences, position) {
	let closest;
	let min = Infinity;

	for (const silence of silences) {
		const value = dist(silence, position);

		if (value < min) {
			min = value;
			closest = silence;
		}
	}

	return closest;
}

export async function detect(clips) {
	const detections = [];
	const filteredClips = clips.filter((clip) => clip.track === 1).sort((a, b) => a.position - b.position);

	let detection;

	for (const clip of filteredClips) {
		const { fadein, fadeout, position } = clip;
		const clipEnd = clip.toOutside(clip.end);
		const silences = await from(clip.recording);

		if (detection) {
			const s = silences.find(
				(s) => clip.toOutside(s[0]) - fadein <= position && position < clip.toOutside(s[1]),
			);

			detections.push([detection, s ? clip.toOutside(s[1]) : position]);
			detection = undefined;
		}

		for (const s of silences.silences) {
			const start = clip.toOutside(s[0]);
			const end = clip.toOutside(s[1]);

			if (position < start && start < clipEnd) {
				detection = start;
			}

			if (position < end && end + fadeout < clipEnd && detection) {
				detections.push([detection, end]);
				detection = undefined;
			}
		}

		if (!detection) {
			detection = clipEnd;
		}
	}

	return detections;
}
