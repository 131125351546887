import JSZip from "jszip";
import { saveAs } from "file-saver";

import arrayChunk from "./arrayChunk";

export default async function zip(folder, files, callback) {
	let count = 1;
	const chunks = arrayChunk(files, 25);

	for (const [index, chunk] of chunks.entries()) {
		const z = new JSZip();
		const name = `${folder}_${index + 1}of${chunks.length}`;

		for (const [file, url] of chunk) {
			callback(`${count} / ${files.length}`);

			const res = await fetch(url);
			const buffer = await res.arrayBuffer();
			z.folder(name).file(`${file}.wav`, buffer, { binary: true });

			count++;
		}

		const content = await z.generateAsync({ type: "blob" });

		saveAs(content, `${name}.zip`);
	}
}
