import { useMemo } from "react";

import { clips } from "../state/clips";
import { useTrack } from "../state/track";

export default function useClipAtPosition(position) {
	const track = useTrack();

	return useMemo(() => position && clips.atPosition(position, undefined, track), [position, track]);
}
