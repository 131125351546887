import Pdf from "astrid-components/lib/components/Assets/Pdf";
import Timeline from "astrid-components/lib/components/Audio/Timeline";

import enableAutoScroll from "../helpers/enableAutoScroll";
import * as firebase from "../helpers/firebase";
import { isAction, onAction } from "../state/action";
import { getProductionProofer, getRole } from "../state/permissions";
import { getProduction, onProductionChanged } from "../state/production";
import { getProductionId } from "../state/productionId";
import { getProofing, setProofing } from "../state/proofing";

import { stopRecorder } from "./recorder";
import { updatePosition } from "./timeline";

export function restoreProofing() {
	stopRecorder();
	enableAutoScroll();

	updatePosition(Timeline.getProofed());

	const state = JSON.parse(localStorage.getItem(getProductionId()));

	if (state && state.page) {
		Pdf.setPage(state.page);
	}
}

onProductionChanged((production) => {
	if (getRole() === "proofer") {
		Timeline.setProofed(production.getProgressAmount("proofed"));
		restoreProofing();
	}
});

onAction(
	({ type, position }) => {
		if (getRole() === "proofer") {
			if (type === "start") {
				setProofing(position <= Timeline.getProofed());
			}

			if (type === "stop" && getProofing()) {
				if (getProductionProofer()) {
					firebase.commit(getProduction().updateProgress("proofed", Timeline.getPosition(), true));
				}

				localStorage.setItem(getProductionId(), JSON.stringify({ page: Pdf.getPage() }));
			}
		}
	},
	false,
	true,
);

Timeline.onPosition((position) => {
	if (getRole() === "proofer" && isAction("start") && getProofing() && position > Timeline.getProofed()) {
		Timeline.setProofed(position);
	}

	if (isAction("stop")) {
		setProofing(position <= Timeline.getProofed());
	}
});
