import createStore from "astrid-helpers/src/createStore";

import * as firebase from "../helpers/firebase";

import { onProductionId } from "./productionId";

export const [getChapters, setChapters, useChapters, onChapters] = createStore();

firebase.events.on("chapters", ({ data }) => setChapters(data));

onProductionId((id) => {
	if (id) {
		firebase.worker.chapters(id);

		return () => setChapters();
	}
});
