import { useEffect, useMemo, useRef, useState } from "react";

import List from "astrid-components/lib/components/Data/List";
import timeToMs from "astrid-helpers/src/timeToMs";

import useParts from "../../hooks/useParts";
import { onSidebar } from "../../state/sidebar";

import Part from "./Part";
import Search from "./Search";

function PartsList() {
	const ref = useRef();
	const parts = useParts();
	const [text, setText] = useState("");

	const search = useMemo(() => text?.toLowerCase(), [text]);

	const filtered = useMemo(
		() => parts.filter((file) => !search || (timeToMs(search) <= file.stop && timeToMs(search) >= file.start)),
		[search, parts],
	);

	const item = (index) => {
		if (index === 0) {
			return <Search text={text} setText={setText} />;
		} else {
			return <Part file={filtered[index - 1]} />;
		}
	};

	useEffect(
		() =>
			onSidebar((sidebar) => {
				if (sidebar === 2) {
					ref.current.scrollToIndex(0);
				}
			}),
		[],
	);

	return <List.Variable itemContent={item} totalCount={filtered.length + 1} ref={ref} />;
}

export default PartsList;
