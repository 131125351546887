import { useCallback } from "react";

import useAsync from "astrid-hooks/src/useAsync";

import * as silences from "../helpers/silences";
import { useGateOptions } from "../state/gateOptions";
import { useTool } from "../state/tool";

export default function useRecordingSilences(recording) {
	const tool = useTool();
	const options = useGateOptions();

	return useAsync(
		useCallback(() => {
			if (recording && options && (tool || !tool)) {
				return silences.from(recording, options);
			}
		}, [options, recording, tool]),
	);
}
