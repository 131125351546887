import { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";

import App from "./components";
import Loading from "./components/Loading";
import { worker } from "./helpers/firebase";
import "./helpers/keyBindings";
import params from "./helpers/params";
import useText from "./hooks/useText";
import useVersion from "./hooks/useVersion";
import "./i18n";
import "./state/highlights";
import { useReader } from "./state/permissions";
import { useProduction } from "./state/production";
import { setProductionId } from "./state/productionId";
import { setStudioId } from "./state/studio";
import { useUid } from "./state/user";
import "./tools";

const token = params.get("token");
const studioId = params.get("studio");
const productionId = params.get("production");

function Switch() {
	useVersion();

	const t = useText();
	const uid = useUid();
	const reader = useReader();
	const production = useProduction();

	const [error, setError] = useState(false);

	useEffect(() => {
		if (token) {
			worker.login(token).then((success) => !success && setError(true));
		}
	}, []);

	useEffect(() => {
		if (uid) {
			if (productionId) {
				setProductionId(productionId);
			}

			if (studioId) {
				setStudioId(studioId);
			}
		}
	}, [uid]);

	if (error) return <Loading text={t("loggingInError", "Something went wrong while logging in")} />;
	if (uid === false) return <Loading text={t("notLoggedIn", "You are not logged in")} />;
	if (!uid) return <Loading loading text={t("loggingIn", "Logging in")} />;
	if (production && !reader) return <Loading text={t("notAuthorized", "You are not authorized")} />;
	if (production && production.version > 2)
		return (
			<Loading text={t("productionNotSupported", "The production is not supported in this version of the app")} />
		);

	return <App />;
}

document.addEventListener("touchmove", (e) => e.touches.length > 1 && e.preventDefault(), { passive: false });

const rootElement = document.getElementById("root");
rootElement.onclick = (e) => e.preventDefault();

const root = createRoot(rootElement);
root.render(<Switch />);
