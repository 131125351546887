import React, { memo } from "react";

import Timeline from "astrid-components/lib/components/Audio/Timeline";

import { useTrack } from "../../state/track";

import Track from "./Track";

function Tracks() {
	const track = useTrack();

	return (
		<Timeline.Tracks>
			<Track track={1} info={track === 1} />
			{track === 2 && <Track track={2} info={track === 2} />}
		</Timeline.Tracks>
	);
}

export default memo(Tracks);
