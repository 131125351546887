import React, { memo } from "react";

import Wave from "astrid-components/lib/components/Assets/Icons/Wave";
import Timeline from "astrid-components/lib/components/Audio/Timeline";
import Range from "astrid-components/lib/components/Inputs/Range";
import Dropdown from "astrid-components/lib/components/Modules/Dropdown";

import Button from "./Button";

function onClick() {
	Timeline.setAmp(1);
	Timeline.resetSize();
}

function Amp() {
	const amp = Timeline.useAmp();

	return (
		<Dropdown
			trigger={
				<Button onClick={onClick}>
					<Wave />
				</Button>
			}
		>
			<Range step={0.1} min={1} max={10} direction="to top" value={amp} onChange={Timeline.setAmp} />
		</Dropdown>
	);
}

export default memo(Amp);
