import React, { memo } from "react";

import Marker from "./Marker";

const types = [["memo"], ["memo"]];

function MemoMarker(props) {
	return <Marker types={types} {...props} />;
}

export default memo(MemoMarker);
