import { debounce } from "debounce";

import Pdf from "astrid-components/lib/components/Assets/Pdf";

import { markers } from "./markers";
import { getSelectedMarker, onSelectedMarker } from "./selectedMarker";
import { getVisibleMarkers, onVisibleMarkers } from "./visibleMarkers";
import { onSelectedWord } from "./word";
import { words } from "./words";

const updateRects = debounce(() => {
	const rects = [];
	const results = Pdf.getSearchResults();
	const searchIndex = Pdf.getSearchIndex();
	const markers = getVisibleMarkers();
	const selectedMarker = getSelectedMarker();

	markers
		.filter((marker) => !marker.checked && marker.rects)
		.forEach((marker) =>
			rects.push({
				color: "negative",
				page: marker.page,
				rects: marker.rects,
				active: selectedMarker && selectedMarker[0] === marker.id,
			}),
		);

	if (results) {
		results.forEach((result, index) =>
			rects.push({
				...result,
				color: "positive",
				active: index === searchIndex,
			}),
		);
	}

	Pdf.setRects(
		rects.reduce(function (rv, x) {
			(rv[x.page] = rv[x.page] || []).push(x);
			return rv;
		}, {}),
	);
}, 300);

Pdf.onSearch(updateRects);
Pdf.onSearchIndex((next, prev) => next !== prev && updateRects());

onSelectedMarker((next, prev) => {
	if (next && (!prev || (next[0] && next[0] !== prev[0]))) {
		const marker = markers.get(next[0]);
		marker && marker.rects && updateRects();
	}
});

const updateHighlights = debounce(() => {
	const highlights = getVisibleMarkers()
		.filter((marker) => !marker.checked && marker.type === "wrong" && marker.wrong)
		.map((marker) => marker.wrong.trim().toLowerCase())
		.filter((value, index, self) => self.indexOf(value) === index)
		.map((text) => ({ text, color: "negative" }))
		.concat(
			words
				.filter((word) => word.word)
				.map((word) => ({
					color: word.contribution ? "positive" : "warning",
					text: word.word,
				})),
		)
		.filter(({ text }) => text !== " ");

	Pdf.setHighlights(highlights);
}, 300);

words.events.on("update", updateHighlights);
onSelectedWord((next, prev) => next && (!prev || next[0] !== prev[0]) && updateHighlights());

onVisibleMarkers(() => {
	updateRects();
	updateHighlights();
});
