import { useActiveUsers } from "../../state/activeUsers";

import UserImg from "../UserImage";

export default function ActiveUsers() {
	const activeUsers = useActiveUsers();
	const currentTime = new Date();
	const minuteAgo = currentTime - 1 * 60000;

	return (
		<>
			{Object.values(activeUsers?.data || {})
				.filter((user) => user.timestamp.seconds * 1000 > minuteAgo)
				.sort((a, b) => a.name.localeCompare(b.name))
				.map((user, index) => (
					<UserImg key={index} src={user.img} name={user.name} />
				))}
		</>
	);
}
