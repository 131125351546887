import React, { memo } from "react";

import useScopeSpaces from "../../hooks/useScopeSpaces";

import Space from "./Space";

function Spaces() {
	const spaces = useScopeSpaces();

	return spaces.map((space) => <Space key={space.position} {...space} />);
}

export default memo(Spaces);
