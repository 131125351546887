import React, { memo, useCallback, useMemo, useState } from "react";

import FileIcon from "astrid-components/lib/components/Assets/Icons/File";
import RotateIcon from "astrid-components/lib/components/Assets/Icons/Rotate";
import Timeline from "astrid-components/lib/components/Audio/Timeline";
import List from "astrid-components/lib/components/Data/List";
import Flex from "astrid-components/lib/components/Layout/Flex";
import Popup from "astrid-components/lib/components/Modules/Popup";
import msToTime from "astrid-helpers/src/msToTime";
import volumeToDecibel from "astrid-helpers/src/volumeToDecibel";
import { push } from "astrid-hooks/src/useHistory";

import cutClips from "../../helpers/cutClips";
import disableAutoScroll from "../../helpers/disableAutoScroll";
import enableAutoScroll from "../../helpers/enableAutoScroll";
import * as firebase from "../../helpers/firebase";
import * as master from "../../helpers/master";
import nudgeTimeline from "../../helpers/nudgeTimeline";
import useRecording from "../../hooks/useRecording";
import useRendered from "../../hooks/useRendered";
import useText from "../../hooks/useText";
import useWord from "../../hooks/useWord";
import { clips } from "../../state/clips";
import { getPrecording, usePrecording } from "../../state/precordings";
import { getProductionId } from "../../state/productionId";
import { setSelectedClipId, useSelectedClipId } from "../../state/selectedClipId";
import { transactions } from "../../state/transactions";
import { useSelectedWord } from "../../state/word";

import Silences from "./Silences";
import Waveform from "./Waveform";
import Word from "./Word";

function onMove() {
	disableAutoScroll();
}

function Clip({ clip, ...props }) {
	usePrecording(clip.number);

	const [loading, setLoading] = useState(false);

	const t = useText();
	const rendered = useRendered(clip);
	const recording = useRecording(clip.number);
	const selected = clip.id === useSelectedClipId();
	const selectedWord = useSelectedWord();
	const word = useWord(selectedWord && selectedWord[0]);

	const onSelect = useCallback(
		(selected) => {
			setSelectedClipId(selected && clip.id);

			if (selected) {
				const precording = getPrecording(clip.number);

				if (precording) {
					precording.moved = true;
				}
			}
		},
		[clip.id, clip.number],
	);

	const onTrimStart = useCallback(
		(position, start, fadein) => {
			push(
				t("trimClip", "Trim clip"),
				firebase.commit([
					master.modify(Math.min(position, clip.position)),
					clip.update({ start, fadein, position, listened: false }),
					transactions.create(clip.id, "end", msToTime(position)),
				]),
			);

			enableAutoScroll(true);
		},
		[clip, t],
	);

	const onTrimEnd = useCallback(
		(end, fadeout) => {
			const right = clips.toRight(clip.position);

			push(
				t("trimClip", "Trim clip"),
				firebase.commit([
					master.modify(Math.min(clip.toOutside(clip.end), clip.toOutside(end))),
					clip.update({ end, fadeout }),
					right && right.update({ listened: false }),
					transactions.create(clip.id, "end", msToTime(clip.toOutside(end))),
				]),
			);

			enableAutoScroll(true);
		},
		[clip, t],
	);

	const onChangePosition = useCallback(
		(position, ripple) => {
			const filteredClips = ripple ? clips.filter(({ position }) => position > clip.position) : [];

			const playPosition = Timeline.getPosition();

			if (clip.position < playPosition && clip.position + clip.end - clip.start > playPosition) {
				Timeline.setPosition(playPosition + position - clip.position);
			}

			push(
				t("moveClip", "Move clip"),
				firebase.commit([
					...cutClips({
						track: clip.track,
						range: [position, clip.toOutside(clip.end, position)],
						ignoreIds: [clip.id, ...filteredClips.map(({ id }) => id)],
					}),
					ripple && nudgeTimeline(clip.position + 1, ripple),
					clip.update({ position, listened: false }),
					transactions.create(clip.id, "position", msToTime(position)),
					master.modify(Math.min(position, clip.position)),
				]),
			);

			enableAutoScroll(true);
		},
		[clip, t],
	);

	const onChangeVolume = useCallback(
		(volume) =>
			push(
				t("changeVolume", "Change volume"),
				firebase.commit([
					clip.update({ volume, listened: false }),
					transactions.create(clip.id, "volume", `${volumeToDecibel(volume)} db`),
					master.modify(clip.position),
				]),
			),
		[clip, t],
	);

	const onChangeFadein = useCallback(
		(fadein) =>
			push(
				t("changeFadeIn", "Change fade in"),
				firebase.commit([
					clip.update({ fadein, listened: false }),
					transactions.create(clip.id, "fadein", `${fadein} ms`),
					master.modify(clip.position),
				]),
			),
		[clip, t],
	);

	const onChangeFadeout = useCallback(
		(fadeout) => {
			const right = clips.toRight(clip.position);

			push(
				t("changeFadeOut", "Change fade out"),
				firebase.commit([
					clip.update({ fadeout }),
					right && right.update({ listened: false }),
					transactions.create(clip.id, "fadeout", `${fadeout} ms`),
					master.modify(clip.position),
				]),
			);
		},
		[clip, t],
	);

	const onTrigger = useCallback(async () => {
		if (!loading) {
			setLoading(true);

			await firebase.worker.call("recordings-trigger", { productionId: getProductionId(), number: clip.number });

			setLoading(false);
		}
	}, [clip.number, loading]);

	const { url, web, name, studio, created, createdBy, error } = recording || {};

	const footer = useMemo(
		() => (
			<>
				<Popup.Trigger
					align="bottom left"
					style={{ marginLeft: 8 }}
					trigger={
						loading || clip.loading ? <RotateIcon size={14} animation="rotate" /> : <FileIcon size={14} />
					}
				>
					<List inverted size="small" padding={5}>
						{clip.id && (
							<List.Item first>
								<Flex justifyContent="flex-start" paddingRight={20}>
									<List.Header>{t("identity", "ID")}</List.Header>
									<List.Text>{clip.id}</List.Text>
								</Flex>
							</List.Item>
						)}
						{created && (
							<List.Item>
								<Flex justifyContent="flex-start" paddingRight={20}>
									<List.Header>{t("recorded", "Recorded")}</List.Header>
									<List.Text>{created}</List.Text>
								</Flex>
							</List.Item>
						)}
						{createdBy && (
							<List.Item>
								<Flex justifyContent="flex-start" paddingRight={20}>
									<List.Header>{t("createdBy", "Created by")}</List.Header>
									<List.Text>{createdBy}</List.Text>
								</Flex>
							</List.Item>
						)}
						{web && (
							<List.Item>
								<Flex justifyContent="flex-start" paddingRight={20}>
									<List.Header>{t("web", "Web")}</List.Header>
									<List.Text>{web}</List.Text>
								</Flex>
							</List.Item>
						)}
						{studio && (
							<List.Item>
								<Flex justifyContent="flex-start" paddingRight={20}>
									<List.Header>{t("studio", "Studio")}</List.Header>
									<List.Text>{studio}</List.Text>
								</Flex>
							</List.Item>
						)}
						{name && url && (
							<List.Item>
								<Flex justifyContent="flex-start" paddingRight={20}>
									<List.Header>{t("audioFile", "Audio")}</List.Header>
									<List.Text>
										<a href={url} target="_blank" rel="noopener noreferrer">
											{name}
										</a>{" "}
										{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}(
										<a onClick={onTrigger}>{t("recover", "Recover")}</a>)
									</List.Text>
								</Flex>
							</List.Item>
						)}
						{!url && (
							<List.Item>
								<Flex justifyContent="flex-start" paddingRight={20}>
									<List.Header>{t("audioFile", "Audio")}</List.Header>
									<List.Text>
										{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
										<a onClick={onTrigger}>{t("recover", "Recover")}</a>
									</List.Text>
								</Flex>
							</List.Item>
						)}
					</List>
				</Popup.Trigger>
				<span>{name}</span>
			</>
		),
		[clip.id, clip.loading, created, createdBy, loading, name, onTrigger, studio, t, url, web],
	);

	if (!recording) return false;

	return (
		<>
			<Timeline.Clip
				{...props}
				footer={footer}
				selected={selected}
				disabled={rendered}
				end={clip.end}
				start={clip.start}
				length={clip.length}
				volume={clip.volume}
				fadein={clip.fadein}
				active={clip.active}
				fadeout={clip.fadeout}
				position={clip.position}
				disableRipple={clip.track === 2}
				onMove={onMove}
				onSelect={onSelect}
				onTrimEnd={onTrimEnd}
				onTrimStart={onTrimStart}
				onChangeVolume={onChangeVolume}
				onChangeFadein={onChangeFadein}
				onChangeFadeout={onChangeFadeout}
				onChangePosition={onChangePosition}
			>
				{!rendered && clip.track === 1 && <Silences clip={clip} recording={recording} />}
				{!error && <Waveform clip={clip} recording={recording} />}
			</Timeline.Clip>
			{word && word.clip === clip.id && <Word word={word} position={clip.toOutside(word.start)} />}
		</>
	);
}

export default memo(Clip);
