import msToTime from "astrid-helpers/src/msToTime";

import { markers } from "../state/markers";
import { getProduction } from "../state/production";

export default function txt() {
	const { title } = getProduction();
	const dateTime = new Date().toLocaleString();

	let text = `${title} - ${dateTime}`;

	markers
		.filter((marker) => !marker.checked)
		.forEach((marker, index) => {
			text += `

${index + 1}
Time: ${msToTime(marker.position)}
Page: ${marker.page}
Label: ${marker.label}${
				marker.text
					? `
Description: ${marker.text}`
					: ""
			}`;
		});

	const link = document.createElement("a");

	link.download = `${title} - ${dateTime}.txt`;
	link.href = "data:text/plain;charset=utf-8," + encodeURIComponent(text);

	link.dispatchEvent(new MouseEvent("click", { bubbles: true, cancelable: true, view: window }));
}
