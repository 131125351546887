import { useDebounce } from "use-debounce";

import React, { memo, useMemo } from "react";

import Timeline from "astrid-components/lib/components/Audio/Timeline";
import useAudioBufferFromURL from "astrid-hooks/useAudioBufferFromURL";

import useAudioWave from "../../hooks/useAudioWave";

const Svg = memo(({ html }) => <div dangerouslySetInnerHTML={{ __html: html }} />);

function Part({ part }) {
	const buffer = useAudioBufferFromURL(part.url);

	const [scope] = useDebounce(Timeline.useScope(), 300);

	const options = useMemo(
		() => ({
			end: Math.min(part.stop - part.start, scope.end - part.start),
			start: Math.max(0, scope.start - part.start),
		}),
		[part.start, part.stop, scope.end, scope.start],
	);

	const wave = useAudioWave(buffer, options);

	return (
		<Timeline.Part start={part.start} stop={part.stop} loading={!wave}>
			{wave && (
				<Timeline.Wave start={wave.start} end={wave.end}>
					{wave.shapes.map((shape, index) => (
						<Svg key={index} {...shape} />
					))}
				</Timeline.Wave>
			)}
		</Timeline.Part>
	);
}

export default memo(Part);
