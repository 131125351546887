import Recordings from "../classes/recordings";

import * as firebase from "../helpers/firebase";

import { clips } from "./clips";
import { onProductionId } from "./productionId";

export const recordings = new Recordings();

firebase.events.on("recordings", (snapshot) => {
	recordings.snapshot = snapshot;
	clips.update(false);
});

onProductionId((id) => {
	if (id) {
		firebase.worker.recordings(id);

		return () => recordings.clear();
	}
});
