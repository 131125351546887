import React, { memo, useCallback, useEffect } from "react";

import Timeline from "astrid-components/lib/components/Audio/Timeline";
import clamp from "astrid-helpers/src/clamp";
import lerp from "astrid-helpers/src/lerp";
import { push } from "astrid-hooks/src/useHistory";
import useMouseTouch from "astrid-hooks/src/useMouseTouch";

import cutClips from "../../helpers/cutClips";
import * as firebase from "../../helpers/firebase";
import { setTool } from "../../state/tool";
import { getTrack } from "../../state/track";

const min = 0;

function Cut({ x, y }) {
	const amp = Timeline.useAmp();
	const max = Timeline.useSize() / 10;

	const length = clamp(lerp(min, max, y), min, max);
	const position = x - length;

	const disabled = y < 0 || y > 1;

	useEffect(
		() => Timeline.setAmp(10) || (() => Timeline.setAmp(amp)),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	useMouseTouch(
		"up",
		useCallback(() => {
			setTool();

			if (!disabled) {
				const fade = max > 70 ? 10 : 0;

				push(
					"Ta bort missljud",
					firebase.commit(
						cutClips({
							track: getTrack(),
							range: [position, x],
							fadein: fade,
							fadeout: fade,
							select: true,
							transaction: true,
						}),
					),
				);
			}
		}, [disabled, max, position, x]),
		window,
		true,
	);

	if (disabled) return false;

	return <Timeline.Range position={position} length={length} />;
}

export default memo(Cut);
