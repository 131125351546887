import React, { memo } from "react";
import styled from "styled-components";

import Arrow from "astrid-components/lib/components/Assets/Icons/Arrow";
import Rotate from "astrid-components/lib/components/Assets/Icons/Rotate";
import Astrid from "astrid-components/lib/components/Assets/Logos/Astrid";
import { isStage } from "astrid-helpers/src/env";

const Root = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	height: 90vh;

	> * + * {
		margin-top: 10px;
	}
`;

const StyledAstrid = styled(Astrid)`
	position: relative;
`;

const Content = styled.div`
	display: flex;
	align-items: center;
	font-size: 16px;
	font-weight: 600;

	> * + * {
		margin-left: 10px;
	}
`;

const Small = styled.div`
	display: flex;
	align-items: center;
	font-size: 14px;
	opacity: 0.5;

	> * + * {
		margin-left: 10px;
	}
`;

function Loading({ loading, text }) {
	return (
		<Root>
			<StyledAstrid width={100} height={100} />
			<Content>
				{loading && <Rotate animation="rotate" />}
				<span>{text}</span>
			</Content>
			{!loading && (
				<Small as="a" href={`https://${isStage ? "stage." : ""}astrid.fm`}>
					<Arrow style={{ transform: "rotate(90deg)" }} />
					<span>Tillbaka till astrid.fm</span>
				</Small>
			)}
		</Root>
	);
}

export default memo(Loading);
