import React, { memo } from "react";

import Label from "astrid-components/lib/components/Inputs/Label";
import Select from "astrid-components/lib/components/Inputs/Select";
import { useOutputs } from "astrid-helpers/src/mediaDevices";

import useText from "../../hooks/useText";
import { setOutput, useOutput } from "../../state/output";

function Output() {
	const t = useText();
	const output = useOutput();
	const outputs = useOutputs();

	return (
		<Label text={t("audioOut", "Audio out")}>
			<Select
				value={output}
				options={outputs}
				placeholder={t("selectAudioOut", "Select audio out")}
				onChange={setOutput}
			/>
		</Label>
	);
}

export default memo(Output);
