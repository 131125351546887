import { useCallback } from "react";

import useAsync from "astrid-hooks/src/useAsync";

import { detect } from "../helpers/silences";
import { useScopeClips } from "../state/scopeClips";

export default function useSilences() {
	const { clips } = useScopeClips();

	return useAsync(
		useCallback(() => detect(clips), [clips]),
		[],
	);
}
