import React, { memo, useMemo } from "react";

import UndoIcon from "astrid-components/lib/components/Assets/Icons/Undo";
import useHistory, { undo, useRedo } from "astrid-hooks/src/useHistory";

import useText from "../../hooks/useText";
import { getAction, useAction } from "../../state/action";

import Button from "./Button";

function onClickUndo() {
	undo(getAction());
}

function Undo() {
	const t = useText();
	const redo = useRedo();
	const action = useAction();
	const history = useHistory();

	const enabled = useMemo(() => history[0]?.[2]?.(action), [action, history]);

	return (
		<>
			<Button
				disabled={!enabled}
				label={history[0] && t("undoAndLabel", "Undo: {{label}}", { label: history[0][0] })}
				onClick={onClickUndo}
			>
				<UndoIcon />
			</Button>
			<Button disabled={!redo} label={t("redo", "Redo")} onClick={redo}>
				<UndoIcon style={{ transform: "scaleX(-1)" }} />
			</Button>
		</>
	);
}

export default memo(Undo);
