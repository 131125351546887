import React, { memo, useMemo, useState } from "react";

import Arrow from "astrid-components/lib/components/Assets/Icons/Arrow";
import Rotate from "astrid-components/lib/components/Assets/Icons/Rotate";
import Timeline from "astrid-components/lib/components/Audio/Timeline";

import { next } from "../../helpers/listen";
import { useActionType } from "../../state/action";
import { useListenClipIds, useListenIndex, useListenStop } from "../../state/listen";
import { useStudioActive } from "../../state/studio";

import Button from "./Button";

function Next() {
	const position = Timeline.usePosition();

	const stop = useListenStop();
	const action = useActionType();
	const index = useListenIndex();
	const clips = useListenClipIds();
	const active = useStudioActive();

	const [loading, setLoading] = useState(false);

	const onClick = async () => {
		setLoading(true);
		await next();
		setLoading(false);
	};

	const disabled = useMemo(
		() => loading || active || index >= clips.length - 1 || (action === "start" && position < stop + 500),
		[loading, active, index, clips.length, action, position, stop],
	);

	return (
		<Button size="small" style={{ width: 42 }} disabled={disabled} onClick={onClick}>
			{loading ? (
				<Rotate size={18} animation="rotate" />
			) : (
				<Arrow size={22} style={{ transform: "rotate(-90deg)" }} />
			)}
		</Button>
	);
}

export default memo(Next);
