import React, { memo } from "react";

import Marker from "./Marker";

const types = [
	["longer", "shorter"],
	["longer", "shorter"],
];

function TimingMarker(props) {
	return <Marker proof types={types} {...props} />;
}

export default memo(TimingMarker);
