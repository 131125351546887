function avg(chunk) {
	return chunk.reduce((total, [, max]) => total + max, 0) / chunk.length;
}

function chunk(data, count) {
	const chunks = [];

	while (data.length) {
		const chunkSize = Math.ceil(data.length / count--);
		const chunk = data.slice(0, chunkSize);
		chunks.push(chunk);
		data = data.slice(chunkSize);
	}

	return chunks;
}

export default class Silence {
	constructor(peaks, silence) {
		this.peaks = peaks;
		this.silence = silence;
	}

	get 0() {
		return (this.silence[0] / this.peaks.size) * this.length;
	}

	get 1() {
		return (this.silence[1] / this.peaks.size) * this.length;
	}

	get length() {
		return (this.peaks.recording && this.peaks.recording.length) || 0;
	}

	get lowest() {
		let count = 3;
		let map = this.peaks.peaks.slice(...this.silence).map(([, max], i) => [i, max]);

		while (count > 0) {
			count--;

			if (map.length >= 3) {
				const chunks = chunk(map, 3);
				const avgs = chunks.map(avg);

				map = chunks[avgs.indexOf(Math.min(...avgs))];
			}
		}

		const values = map.map(([, max]) => max);
		const low = map[values.indexOf(Math.min(...values))] || [0];

		return ((this.silence[0] + low[0]) / this.peaks.size) * this.length;
	}

	isWithin(position) {
		return this[0] <= position && this[1] >= position;
	}
}
