import React, { memo, useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { useDebounce } from "use-debounce";

import Timeline from "astrid-components/lib/components/Audio/Timeline";
import useAsync from "astrid-hooks/src/useAsync";

import useAudioWave from "../../hooks/useAudioWave";
import useBuffer from "../../hooks/useBuffer";
import useStorageURL from "../../hooks/useStorageURL";
import { getPrecording, usePrecording } from "../../state/precordings";

const amp = 3;

const Svg = memo(({ html }) => <div dangerouslySetInnerHTML={{ __html: html }} />);

const Peaks = memo(({ amp, height, number }) => {
	const half = height / 2;

	const ref = useRef();
	const over = useRef();
	const under = useRef();
	const prevLength = useRef(0);

	const precording = usePrecording(number);

	const length = precording ? precording.peaks.length : 0;

	useEffect(() => {
		if (length > prevLength.current) {
			const svg = ref.current;
			const slice = getPrecording(number).peaks.slice(prevLength.current, length - 1);

			for (let i = 0; i < slice.length; i += 1) {
				if (slice[i]) {
					const l = slice[i][0] * half;
					const h = slice[i][1] * half;
					const x = prevLength.current + i;

					const point1 = svg.createSVGPoint();
					point1.x = x;
					point1.y = Math.round((half - h) * 100) / 100;

					const point2 = svg.createSVGPoint();
					point2.x = x;
					point2.y = Math.round((half + l) * 100) / 100;

					if (i === 0) {
						const index = over.current.points.length - 1;
						over.current.points.replaceItem(point1, index);
						under.current.points.replaceItem(point2, index);
					} else {
						over.current.points.appendItem(point1);
						under.current.points.appendItem(point2);
					}
				}
			}

			const point = svg.createSVGPoint();
			point.x = length - 1;
			point.y = half;
			over.current.points.appendItem(point);
			under.current.points.appendItem(point);
			prevLength.current = length;
		}
	}, [half, length, number]);

	if (!precording) return false;

	return (
		<Timeline.Wave start={0} end={precording.length} amp={amp}>
			<svg
				ref={ref}
				width={length}
				height={height}
				version="1.1"
				preserveAspectRatio="none"
				viewBox={`0 0 ${length} ${height}`}
				xmlns="http://www.w3.org/2000/svg"
			>
				<polygon ref={over} fill="currentColor" points={`0,${half} 0,${half}`} />
				<polygon ref={under} fill="currentColor" points={`0,${half} 0,${half}`} />
			</svg>
		</Timeline.Wave>
	);
});

function Waveform({ clip, recording }) {
	const scope = Timeline.useScope();
	const rect = Timeline.useRect();

	const [debouncedScope] = useDebounce(scope, 300);
	const { trimming } = useContext(Timeline.ClipContext);
	const height = useMemo(() => Math.round(rect.height - 36), [rect.height]);

	const buffer = useBuffer(recording.url);
	const url = useStorageURL(recording.fill);

	const html = useAsync(
		useCallback(
			() =>
				url &&
				fetch(url)
					.then((res) => res.text())
					.catch(() => {}),
			[url],
		),
	);

	const options = useMemo(() => {
		const volume = clip.volume;
		const start = Math.max(!trimming ? clip.start : 0, clip.toInside(debouncedScope.start));
		const end = Math.min(!trimming ? clip.end : clip.length, clip.toInside(debouncedScope.end));
		const fadein = trimming ? 0 : Math.max(0, clip.fadein - (start - clip.start));
		const fadeout = trimming ? 0 : Math.max(0, clip.fadeout - (clip.end - end));

		return {
			end,
			start,
			height,
			volume,
			fadein,
			fadeout,
		};
	}, [clip, trimming, debouncedScope.start, debouncedScope.end, height]);

	const wave = useAudioWave(buffer, options);

	if (wave) {
		const { shapes, ...props } = wave;

		return (
			<Timeline.Wave amp={amp} {...props}>
				{shapes.map((shape, index) => (
					<Svg key={index} {...shape} />
				))}
			</Timeline.Wave>
		);
	}

	if (html)
		return (
			<Timeline.Wave start={0} end={recording.length} amp={clip.volume * amp}>
				<Svg html={html} />
			</Timeline.Wave>
		);

	return <Peaks height={height} number={recording.id} amp={clip.volume * amp} />;
}

export default memo(Waveform);
