const fields = {
	sentence: {
		as: "textarea",
		key: "sentence",
		label: "Mening(ar)",
	},
	description: {
		as: "textarea",
		key: "description",
		label: "Beskrivning",
	},
	wrong: {
		as: "input",
		key: "wrong",
		label: "Fel ord",
	},
	right: {
		as: "input",
		key: "right",
		label: "Rätt ord",
	},
};

const markerTypes = {
	edit: {
		label: "Felläsning",
		color: "negative",
		fields: [fields.sentence, fields.description],
	},
	missing: {
		label: "Utelämnad(e) mening(ar)",
		color: "negative",
		fields: [fields.sentence],
	},
	wrong: {
		label: "Fel uttal",
		color: "negative",
		fields: [fields.sentence, fields.wrong, fields.description],
	},
	script: {
		label: "Manusfel",
		color: "negative",
		fields: [fields.sentence, fields.description],
	},
	longer: {
		label: "För kort paus",
		color: "negative",
	},
	shorter: {
		label: "För lång paus",
		color: "negative",
	},
	paragraph: {
		label: "Felaktig avsnittspaus",
		color: "primary",
	},
	chapter: {
		label: "Felaktig kapitelpaus",
		color: "positive",
	},
	wrongPause: {
		label: "Felaktig paus",
		color: "negative",
	},
	memo: {
		label: "Memo",
		color: "default",
		fields: [fields.description],
	},
	noise: {
		label: "Missljud",
		color: "negative",
		fields: [fields.description],
	},
	noisePause: {
		label: "Missljud i Paus",
		color: "negative",
		fields: [fields.description],
	},
	noiseContent: {
		label: "Missljud i mening",
		color: "negative",
		fields: [fields.sentence, fields.description],
	},
};

export default markerTypes;
