import React, { memo, useCallback } from "react";

import Pdf from "astrid-components/lib/components/Assets/Pdf";
import List from "astrid-components/lib/components/Data/List";
import Button from "astrid-components/lib/components/Inputs/Button";
import Flex from "astrid-components/lib/components/Layout/Flex";
import msToTime from "astrid-helpers/src/msToTime";
import padNum from "astrid-helpers/src/padNum";

import { jumpToPosition } from "../../../services/timeline";

function ListItem({ text, page, color, position, children, ...props }) {
	const onClickPage = useCallback(
		(e) => {
			e.stopPropagation();
			Pdf.setPage(page);
		},
		[page],
	);

	const onClickTime = useCallback(
		(e) => {
			e.stopPropagation();
			jumpToPosition(position);
		},
		[position],
	);

	return (
		<Flex height={45} padding="0 10px" cursor="pointer" {...props}>
			{children}
			<Button transparent size="tiny" textSize="small" color={color} onClick={onClickPage}>
				{padNum(page, 3)}
			</Button>
			<List.Text>{text}</List.Text>
			<Flex marginLeft="auto">
				<Button transparent size="tiny" textSize="small" color={color} onClick={onClickTime}>
					{msToTime(position, false)}
				</Button>
			</Flex>
		</Flex>
	);
}

export default memo(ListItem);
