export default (port = 443) => [
	{
		urls: "stun:turn.astrid.fm:3478",
		username: "astridturnZf6780fOhiX52Hyk",
		credential: "Piwlxx7MsxtCwReXx3yn6RqCTxH0diy79SRM",
	},
	{
		urls: `turn${port === 443 ? "s" : ""}:turn.astrid.fm:${port}`,
		username: "astridturnZf6780fOhiX52Hyk",
		credential: "Piwlxx7MsxtCwReXx3yn6RqCTxH0diy79SRM",
	},
	{ urls: "stun:stun.l.google.com:19302" },
];
