import React, { memo, useCallback } from "react";

import Timeline from "astrid-components/lib/components/Audio/Timeline";
import useTemp from "astrid-hooks/src/useTemp";

import { setLasso, useLasso } from "../../state/lasso";

function Lasso() {
	const lasso = useLasso();
	const [tempLasso, setTempLasso] = useTemp(lasso);

	const onMove = useCallback(
		(side, delta) => {
			const copy = [...lasso];
			copy[side] = lasso[side] + delta;
			if (side === 0) copy[1] -= delta;
			setTempLasso(copy);
		},
		[lasso, setTempLasso],
	);

	const onRelease = useCallback(() => setLasso(tempLasso), [tempLasso]);

	if (!tempLasso) return false;

	return (
		tempLasso && (
			<Timeline.Range
				expand
				// color={marker.color}
				position={tempLasso[0]}
				length={tempLasso[1]}
				onMove={onMove}
				onRelease={onRelease}
			/>
		)
	);
}

export default memo(Lasso);
