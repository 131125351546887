import React, { memo } from "react";

import Timeline from "astrid-components/lib/components/Audio/Timeline";

import { useScopeClips } from "../../state/scopeClips";

import Clip from "./Clip";

function sortLeft(a, b) {
	return b.toOutside(b.end) - a.toOutside(a.end);
}

function sortRight(a, b) {
	return a.position - b.position;
}

function Track({ info, track }) {
	const { scope, clips } = useScopeClips();

	const sortedLeft = clips.slice().sort(sortLeft);
	const sortedRight = clips.slice().sort(sortRight);

	return (
		<Timeline.Track>
			{clips
				.filter((clip) => clip.track === track && clip.recording)
				.map((a) => {
					const left = sortedLeft.find((b) => b.position <= a.position);
					const prev = sortedLeft.find((b) => b.toOutside(b.end) <= a.position);
					const next = sortedRight.find((b) => b.position >= a.toOutside(a.end));
					const prevClipEnd = prev ? a.toInside(prev.toOutside(prev.end)) : -Infinity;
					const nextClipStart = next ? a.toInside(next.position) : Infinity;
					const enableMove = left && left.toOutside(left.end) > scope.offset;

					return (
						<Clip
							key={a.key}
							clip={a}
							info={info}
							enableMove={enableMove}
							prevClipEnd={prevClipEnd}
							nextClipStart={nextClipStart}
						/>
					);
				})}
		</Timeline.Track>
	);
}

export default memo(Track);
