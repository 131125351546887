import Pdf from "astrid-components/lib/components/Assets/Pdf";
import Timeline from "astrid-components/lib/components/Audio/Timeline";
import context from "astrid-helpers/src/audioContext";

import { startRecorder } from "../services/recorder";
import { clips } from "../state/clips";
import { getListenClipIds, getListenIndex, setListenIndex, setListenStop } from "../state/listen";
import { getManager, getProductionEditor } from "../state/permissions";
import { getProduction } from "../state/production";

import enableAutoScroll from "./enableAutoScroll";
import * as firebase from "./firebase";

export async function listen() {
	const production = getProduction();
	const productionEditor = getProductionEditor();
	const manager = getManager();

	const rightClip = clips.get(getListenClipIds()[getListenIndex()]);

	if (rightClip) {
		let start = rightClip.position;
		let stop = rightClip.position;

		const end = rightClip.toOutside(rightClip.end);
		const leftClip = clips.toLeft(end);

		if (leftClip?.toOutside(leftClip.end) < rightClip.position) {
			start = leftClip.toOutside(leftClip.end);
		}

		startRecorder({ position: start - 1000 });

		setListenStop(stop);

		Pdf.setPage(rightClip.page);
		Timeline.resetSize();
		enableAutoScroll();

		if (productionEditor || manager) {
			firebase.commit([
				production.updateProgress("edited", end),
				!rightClip.listened && rightClip.update({ listened: true }),
			]);
		}
	}
}

let time = context.currentTime;

export function prev() {
	if (context.currentTime - time < 0.25) {
		setListenIndex((index) => index - 1);
	}

	time = context.currentTime;
	return listen();
}

export function next() {
	setListenIndex((index) => index + 1);

	return listen();
}
