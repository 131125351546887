import msToTime from "astrid-helpers/src/msToTime";

import Document from "./document";

export default class Part extends Document {
	get start() {
		return this.data.start;
	}

	get stop() {
		return this.data.stop;
	}

	get length() {
		return this.stop - this.start;
	}

	get status() {
		return this.data.status;
	}

	get bounce() {
		return this.data.bounce;
	}

	get master() {
		return this.data.master;
	}

	get name() {
		return `${msToTime(this.start)}-${msToTime(this.stop)}`;
	}

	get url() {
		if (this.data.aac) return this.data.aac.url;

		return null;
	}

	toInside(value) {
		return value - this.start;
	}

	isInside(value) {
		return this.start <= value && value <= this.stop;
	}
}
