import Cache from "lru-cache";

import Peaks from "../classes/peaks";
import { getPrecording } from "../state/precordings";
import { getProductionId } from "../state/productionId";

import { worker } from "./audio";

export const cache = new Cache({ max: 100 });

async function decode(recording) {
	try {
		const audio = await recording.buffer;

		return await worker.peaks(audio.getChannelData(0));
	} catch (error) {
		return [];
	}
}

export function from(recording) {
	const key = getProductionId() + recording.id;

	if (cache.has(key)) {
		return cache.get(key);
	}

	if (recording.url) {
		const promise = (async () => {
			const peaks = new Peaks(recording, await decode(recording));

			cache.set(key, peaks);

			return peaks;
		})();

		cache.set(key, promise);
	}

	const precording = getPrecording(recording.id);

	if (precording?.peaks) {
		return precording.peaks;
	}

	return cache.get(key) || new Peaks(recording);
}
