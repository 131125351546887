import Pdf from "astrid-components/lib/components/Assets/Pdf";
import createStore from "astrid-helpers/src/createStore";

import { jumpToPosition } from "../services/timeline";

import { isAction } from "./action";
import { setSidebar } from "./sidebar";
import { words } from "./words";

let play = 0;

export const [getSelectedWord, updateSelectedWord, useSelectedWord, onSelectedWord] = createStore(null);

export function useIsSelectedWord(id) {
	const selected = useSelectedWord();

	return [selected && selected[0] === id, selected && selected[1]];
}

export function setSelectedWord(id, autoPlay = false) {
	updateSelectedWord(id && [id, autoPlay && play++]);
}

Pdf.onSelection((selection) => {
	if (selection && selection.done) {
		// const regexp = new RegExp(selection.text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&"), "i");
		const search = selection.text.toLowerCase();
		const word = words.find((word) => word.search === search);

		word && setSelectedWord(word.id, true);
	}
});

onSelectedWord((selected) => {
	if (selected) {
		setSidebar(1);

		const word = words.get(selected[0]);

		if (!isAction("record") && word && word.position) {
			jumpToPosition(word.position);
		}
	}
});
