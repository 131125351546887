export default class Peaks {
	constructor(recording, peaks = []) {
		this.peaks = peaks;
		this.recording = recording;
	}

	get length() {
		return this.peaks.length;
	}

	get size() {
		return this.length - 1;
	}

	append(peaks = []) {
		this.peaks.push(...peaks);
	}

	slice(...args) {
		return this.peaks.slice(...args);
	}
}
