import createStore from "astrid-helpers/src/createStore";

export const [getAction, setAction, useAction, onAction] = createStore({ type: "stop" });

export function getActionType() {
	return getAction().type;
}

export function useActionType() {
	return useAction().type;
}

export function isAction(action) {
	return getActionType() === action;
}
