import React from "react";

import Button from "astrid-components/lib/components/Inputs/Button";
import padNum from "astrid-helpers/src/padNum";

export default function PageButton({ children, ...props }) {
	return (
		<Button transparent size="tiny" textSize="small" {...props}>
			{padNum(children, 3)}
		</Button>
	);
}
