import { useMemo } from "react";

import { useVisibleMarkers } from "../state/visibleMarkers";

export default function useFilteredMarkers(input, filter) {
	const markers = useVisibleMarkers();
	const flattened = useMemo(() => filter.flat(), [filter]);

	return useMemo(
		() =>
			markers.filter(
				(marker) =>
					(!filter || (marker.checked ? flattened.includes("checked") : flattened.includes(marker.type))) &&
					(!input || marker.includes(input)),
			),
		[markers, filter, flattened, input],
	);
}
