import React, { memo } from "react";

import Label from "astrid-components/lib/components/Data/Label";

import { useListenLength } from "../../state/listen";

function Listen() {
	const length = useListenLength();

	return (
		<Label color={length ? "primary" : "positive"} size="small" textSize="large">
			{length}
		</Label>
	);
}

export default memo(Listen);
