import median from "./median";

function walk(data) {
	const walkLeft = (index) => {
		if (index === data.length - 1) return index;

		let min = index;
		const length = Math.min(7, index);

		if (length > 0) {
			for (let i = index - 1; i >= index - length; i--) {
				if (data[i] < data[min]) {
					min = i;
				}
			}
		}

		if (min !== index) {
			return walkLeft(min);
		}

		return min;
	};

	const walkRight = (index) => {
		if (index === 0) return index;

		let min = index;
		const length = Math.min(10, data.length - index - 1);

		if (length > 0) {
			for (let i = index + 1; i <= index + length; i++) {
				if (data[i] < data[min]) {
					min = i;
				}
			}
		}

		if (min !== index) {
			return walkLeft(min);
		}

		return min;
	};

	return ([start, end]) => [walkRight(start), walkLeft(end)];
}

export const defaultOptions = {
	min: 0.02,
	max: 1,
	base: 1,
	high: 0.15,
	low: 0.06,
	hold: 20,
	release: 0.97,
	padding: 3,
};

export default function findSilences(data = [], options = defaultOptions) {
	const { min, max } = options;
	const maxValue = median(data.filter((v) => v >= min && v <= max));
	const groups = [];

	let group;
	let value = 0;
	let index = 0;
	let length = 0;
	let active = data[0] > options.high;

	const high = options.base * options.high * maxValue;
	const low = options.base * options.low * maxValue;

	for (let i = 0; i < data.length; i += 1) {
		value = Math.max(0, value * options.release, Math.min(high, data[i]));

		if (!active) {
			if (value >= high) {
				length = 0;
				active = true;
				group = [index, Math.max(index, Math.max(index, i - options.padding))];

				if (group[1] > 0) {
					groups.push(group);
				}
			}
		} else {
			if (value < low) {
				if (length === 0) {
					index = i;
					length += 1;
				} else if (length > options.hold) {
					active = false;
					length = 0;
				} else {
					length += 1;
				}
			} else {
				length = 0;
			}
		}
	}

	if (!active && index > 0) {
		groups.push([index, data.length - 1]);
	}

	return groups.map(walk(data));
	// .map(([start, end]) => [start / data.length, end / data.length, data[start], data[end]]);
}
