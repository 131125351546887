import Pdf from "astrid-components/lib/components/Assets/Pdf";
import Timeline from "astrid-components/lib/components/Audio/Timeline";
import * as activityTimer from "astrid-helpers/src/activityTimer";
import createStore from "astrid-helpers/src/createStore";

import Production from "../classes/production";
import * as firebase from "../helpers/firebase";

import { onProductionId } from "./productionId";
import { getUid } from "./user";

export const [, setProductionChanged, , onProductionChanged] = createStore();
export const [getProduction, setProduction, useProduction, onProduction] = createStore();

export function updateProduction(data) {
	return getProduction().update(data);
}

firebase.events.on("production", ({ id, data }) => setProduction(new Production(id, data)));

onProduction((next, prev) => {
	if (next && (!prev || next.id !== prev.id)) {
		setProductionChanged(next);
	}
});

onProductionId((productionId) => {
	if (productionId) {
		console.log("id", productionId);

		Timeline.setAmp(1);

		firebase.worker.production(productionId);
		firebase.worker.call("productions-script", { productionId }).then(Pdf.setDocument);

		return () => {
			Pdf.setDocument();

			Timeline.setOffset(0);
			Timeline.setSize(Timeline.initSize);
			Timeline.setPosition(0);
			Timeline.resetDuration();

			setProduction();
		};
	}
});

let interval;

onProductionChanged((production) => {
	clearInterval(interval);

	const path = `${production.path}/users/${getUid()}`;

	let loaded = false;

	firebase.worker.get(path).then(({ data }) => {
		activityTimer.set(data?.activity?.seconds || 0);
		loaded = true;
	});

	interval = setInterval(
		() => loaded && firebase.worker.set(path, { activity: { seconds: activityTimer.get() } }),
		60 * 1000,
	);
});
