import Pdf from "astrid-components/lib/components/Assets/Pdf";

import * as firebase from "../helpers/firebase";
import { getProduction } from "../state/production";

import Collection from "./collection";
import Word from "./word";

export default class Words extends Collection {
	get path() {
		return "words";
	}

	set(id, data) {
		return super.set(id, new Word(id, data, this));
	}

	sorter(a, b) {
		return a.word.localeCompare(b.word);
	}

	async create(text, wordLanguage = "sv") {
		Pdf.setSelection();

		text = text.charAt(0).toUpperCase() + text.slice(1);

		const { id, author, language } = getProduction();

		const data = {
			author,
			index: true,
			content: "",
			wordLanguage,
			productionLanguage: language,
		};

		const match = await firebase.worker.findWord(text);

		if (match) {
			const word = new Word(match.id, match, this);

			return word.update({
				[`productions.${id}`]: data,
			});
		}

		return super.create(
			new Word(
				null,
				{
					word: text,
					productions: {
						[id]: data,
					},
				},
				this,
			),
		);
	}
}
