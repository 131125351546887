import React, { memo } from "react";

import Timeline from "astrid-components/lib/components/Audio/Timeline";

import { useActionType } from "../../state/action";
import { useDuration } from "../../state/duration";
import { useRecorder } from "../../state/permissions";
import { useProduction } from "../../state/production";

const min = 1000 * 60;

function Navigation() {
	const action = useActionType();
	const recorder = useRecorder();
	const size = Timeline.useSize();
	const production = useProduction();
	const duration = useDuration();

	if (!production) return false;

	const editedTime = production.getProgressTime("edited");
	const proofedTime = production.getProgressTime("proofed");
	const renderedTime = production.rendered;

	return (
		<Timeline.Navigation disabled={action === "record" || size <= 5000}>
			{recorder && (
				<>
					{renderedTime > min && <Timeline.Progress value={renderedTime} />}
					{editedTime > min && <Timeline.Progress color="negative" value={editedTime} />}
					{proofedTime > min && <Timeline.Progress color="positive" value={proofedTime} />}
					{duration > min && <Timeline.Progress color="black" value={duration} />}
				</>
			)}
		</Timeline.Navigation>
	);
}

export default memo(Navigation);
