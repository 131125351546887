import { clips } from "../state/clips";

import Document from "./document";

export default class Transaction extends Document {
	get type() {
		return this.data.type;
	}

	get value() {
		return this.data.value;
	}

	get created() {
		return this.data.created;
	}

	get createdBy() {
		return this.data.createdBy;
	}

	get clip() {
		return clips.get(this.data.id);
	}

	get time() {
		const date = new Date(this.created.seconds * 1000);

		return `${date.getMonth() + 1}/${date.getDate()} ${date.getHours()}:${date.getMinutes()}`;
	}
}
