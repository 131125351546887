// import intersection from "lodash/intersection";
import { languages } from "astrid-helpers/src/languages";

import * as firebase from "../helpers/firebase";
import { getProductionId } from "../state/productionId";
import { getProfile, getUid } from "../state/user";

import Document from "./document";

export default class Word extends Document {
	constructor(...args) {
		super(...args);

		const productionId = getProductionId();

		this.word = this.data.word;
		this.choice = this.data.choice && this.data.choice[productionId];

		this.contributions = this.data.contributions || {};
		this.contribution = this.contributions?.[this.choice];

		this.productions = this.data.productions || {};
		this.production = this.productions?.[productionId];

		if (this.contribution) {
			this.url = this.contribution.file;
			this.clip = this.contribution.clip;
			this.start = this.contribution.start;
			this.length = this.contribution.length;
			this.production = this.productions[this.contribution.production];
		}

		if (this.production) {
			this.wordLanguage = this.production.wordLanguage;
			this.productionLanguage = this.production.productionLanguage;
		}

		this.search = this.word.toLowerCase();
		this.forvoUrl = `https://${this.productionLanguage}.forvo.com/search/${this.word}/${this.wordLanguage}`;

		// if (!this.choice) {
		// 	const winner = Object.keys(this.contributions)
		// 		.map(key => {
		// 			const cont = this.contributions[key];
		// 			const prod = this.productions[cont.production];
		// 			const thisProd = this.productions[productionId];
		// 			let points = 0;

		// 			if (thisProd && prod) {
		// 				if (thisProd.productionLanguage === prod.productionLanguage) points += 2;
		// 				if (thisProd.wordLanguage === prod.wordLanguage) points += 2;
		// 				if (intersection(thisProd.author, prod.author).length) points += 3;
		// 			}

		// 			return {
		// 				key,
		// 				...cont,
		// 				points,
		// 			};
		// 		})
		// 		.sort((a, b) => (a.points < b.points ? 1 : -1))
		// 		.find(pointedCont => pointedCont.file);

		// 	this.choice = winner && winner.key;
		// }
	}

	get key() {
		return super.key + this.choice;
	}

	getFile(choice) {
		const { file, start, length } = this.contributions?.[choice || this.choice] || {};

		return {
			file,
			start,
			length,
		};
	}

	remove() {
		return this.update({ [`productions.${getProductionId()}.index`]: false });
	}

	language(key) {
		const { production } = this.contributions[key];
		const { wordLanguage, productionLanguage } = this.productions[production];

		let text = languages[wordLanguage].sv || languages[wordLanguage].name;

		if (wordLanguage !== productionLanguage) {
			text += ` (${languages[productionLanguage].sv || languages[productionLanguage].name})`;
		}

		return text;
	}

	choose(key) {
		return this.update({ [`choice.${getProductionId()}`]: key });
	}

	save(data = {}, id) {
		const user = getUid();
		const production = getProductionId();

		id =
			id ||
			Object.keys(this.contributions).find(
				(id) => this.contributions[id].user === user && this.contributions[id].production === production,
			) ||
			+new Date();

		this.update({
			[`choice.${production}`]: `${id}`,
			[`contributions.${id}`]: {
				user,
				production,
				time: "timestamp",
				email: getProfile().email,
				...data,
			},
		});

		return id;
	}

	async saveBlob(blob) {
		const filePath = `wordSamples/${this.id}/sample-${+new Date()}.mp3`;
		const file = await firebase.worker.upload(filePath, blob, { contentType: "audio/mp3" }, "public");

		return this.save({ file, filePath });
	}
}
