import React, { memo, useCallback } from "react";
import Highlighter from "react-highlight-words";

import Pdf from "astrid-components/lib/components/Assets/Pdf";
import List from "astrid-components/lib/components/Data/List";
import Flex from "astrid-components/lib/components/Layout/Flex";
import Mark from "astrid-components/lib/components/Text/Mark";

import PageButton from "./Common/PageButton";

function Result({ text, page, search, index, active, ...props }) {
	const onClick = useCallback(() => Pdf.setSearchIndex(index), [index]);

	const onClickPage = useCallback(
		(e) => {
			e.stopPropagation();
			Pdf.setPage(page);
		},
		[page],
	);

	return (
		<List.Item active={active} onClick={onClick} {...props}>
			<Flex minHeight={50} height="auto" padding={10} justifyContent="flex-start">
				<PageButton onClick={onClickPage}>{page}</PageButton>
				<Flex paddingLeft={10}>
					<Highlighter searchWords={[search]} highlightTag={Mark} textToHighlight={text} />
				</Flex>
			</Flex>
		</List.Item>
	);
}

export default memo(Result);
