import React, { memo } from "react";

import Marker from "./Marker";

const types = [["edit", "wrong", "script"], ["missing"]];

function ContentMarker(props) {
	return <Marker proof types={types} {...props} />;
}

export default memo(ContentMarker);
