function dist(silence, position) {
	const dist1 = Math.abs(silence[0] - position);
	const dist2 = Math.abs(silence[1] - position);

	return dist1 < dist2 ? dist1 : dist2;
}

export default class Silences {
	constructor(silences) {
		this.silences = silences;
	}

	find(fn) {
		return this.silences.find(fn);
	}

	findSilence(position) {
		return this.find((silence) => silence.isWithin(position));
	}

	findClosestSilence(position, min = Infinity) {
		let closest;

		for (const silence of this.silences) {
			const value = dist(silence, position);

			if (value < min) {
				min = value;
				closest = silence;
			}
		}

		return closest;
	}
}
