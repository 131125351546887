import React, { memo } from "react";

import Master from "./Master";
import Settings from "./Settings";
import Chapters from "./Chapters";
import Download from "./Download";

function Project() {
	return (
		<div
			style={{
				padding: 10,
			}}
		>
			<Settings />
			<Chapters />
			<Master />
			<Download />
		</div>
	);
}

export default memo(Project);
