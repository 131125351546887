import React from "react";

import Text from "astrid-components/lib/components/Text/Text";

export default function Content({ color, label, children, ...props }) {
	return (
		<div {...props}>
			<Text fontSize={12} lineHeight={1.2} color={color}>
				{label}
			</Text>
			<Text fontSize={14}>{children}</Text>
		</div>
	);
}
