import createStore from "astrid-helpers/src/createStore";

import i18n from "../i18n";

export const options = [
	{
		value: "chapter",
		label: i18n.t("chapter", "Chapter"),
		color: "positive",
	},
	{
		value: "paragraph",
		label: i18n.t("section", "Section"),
		color: "primary",
	},
];

export const [getPauseFilter, setPauseFilter, usePauseFilter] = createStore(options.map(({ value }) => value));
